document.addEventListener("turbolinks:load", function() {
  if(typeof(credential_order_id) == 'undefined'){
    credential_order_id = '';
  }

  var lease = $(".datepicker", ".edit-renter");
  lease.datetimepicker({format: 'L'});

  var check_use_bluetooth_reader = "";
  if(window.ReadCardForBlueTooth && window.ReadCardForBlueTooth != undefined){
    if(window.ReadCardForBlueTooth.blueToothStatus() == "1")
      {
        check_use_bluetooth_reader = "1";
      }else{
        check_use_bluetooth_reader = "0";
      }

  }else{
    check_use_bluetooth_reader = "0";
  };

  $("#renter_detail_take_car_way").change(function(){
    var guarantor = $("#guarantor-fieldset");
    var guarantor_fields = [
      "telphone", "name", "credentials_type", "credentials_no",
      "credentials_begin_at", "credentials_end_at", "gender"
    ];
    guarantor_fields.forEach(function(item, _) {
      $("#renter_detail_guarantor_"+item).val("");
    });
    if ($(this).val() == 'take_by_guarantor') {
      guarantor.show();
    } else {
      guarantor.hide();
    }
  });

  $("input[name='search-renter']").click(function(){
    var btn = $(this)
    var mobile_or_id_number = $("#renter").val(),
        _this = $(this),
        query = {
          "q[id_number_or_mobile_eq]": mobile_or_id_number,
          timestamp: (new Date()).valueOf(),
          "need_emergency_info": 1,
          "check_take_car_permission": 1,
          // "check_first_rent": 1,
          "credentials_no": $("#id_number").val(),
          "is_read_card": 0,
          "order_id": credential_order_id,
          "check_use_bluetooth_reader": check_use_bluetooth_reader
        };
    $.getJSON("/members/search.json", query, function(renter) {
      $('.show_search_msg_span').html('');
      if(renter.code === "0"){
        $('.show_search_msg_span').html(show_err_span("未找到相关会员信息"));
        return false;
      }
      if(renter.not_pass){
        alert("很抱歉，只能搜索非身份证且非首租的会员信息");
        return false;
      }
      if(renter.msg){
        alert(renter.msg);
      }

      $("#renter_detail_credentials_type").val(renter.id_type);
      $("#renter_detail_credentials_type").change()
      $("#renter_detail_telphone").val(renter.mobile);
      $("#renter_detail_name").val(renter.member_name);
      $('#renter_detail_birthday').val(renter.birthday)
      $("#renter_detail_credentials_no").val(renter.id_number);
      $("#renter_detail_credentials_begin_at").val(renter.id_date_from);
      $("#renter_detail_credentials_end_at").val(renter.id_date_to);

      $("#renter-begin-at").val(renter.id_date_from);
      $("#renter-end-at").val(renter.id_date_to);
      $("#renter-name").val(renter.member_name);
      $("#renter-no").val(renter.id_number);

      $("#renter_detail_contract_begin_at").val(renter.contrat_from);
      $("#renter_detail_contract_end_at").val(renter.contrat_to);

      $("#address").val(renter.address);
      $("#renter_detail_driving_license_no").val(renter.driver_lience);
      $("#renter_detail_driving_license_end_at").val(renter.lience_date);
      $("#renter_detail_driving_license_first_get_at").val(renter.driving_license_first_get_at);
      $("#renter_detail_driving_license_start_at").val(renter.driving_license_start_at);
      $("#renter_detail_gender").val(renter.sex);
      // 紧急联系人
      $("#renter_detail_emergency_contact").val(renter.emergency_contact);
      $("#renter_detail_emergency_contact_telphone").val(renter.emergency_telphone);
    });
  });

  $('#renter_detail_credentials_type').change(function () {
    var birthdayInput = $('#renter_detail_birthday')
    if (birthdayInput.length < 1) { return }
    if ($(this).val() !== '身份证' && birthdayInput.val().length < 1) {
      birthdayInput.removeAttr('disabled')
    } else {
      birthdayInput.attr('disabled', true)
    }
  })

  $("input[name='search-guarantor']").click(function(){
    var mobile_or_id_number = $("#guarantor").val(),
        _this = $(this),
        query = {
          "q[id_number_or_mobile_eq]": mobile_or_id_number,
           timestamp: (new Date()).valueOf(),
          //  "check_first_rent": 1,
           "is_guarantor": 1,
           "is_read_card": 0,
           "order_id": credential_order_id,
           "check_use_bluetooth_reader": check_use_bluetooth_reader
        };
    $.getJSON("/members/search.json", query, function(guarantor) {
      $('.show_search_msg_span').html('');
      if(guarantor.code === "0"){
        $('.show_search_msg_span').html(show_err_span("未找到相关会员信息"));
        return false;
      }
      if(guarantor.not_pass){
        alert("很抱歉，只能搜索非身份证且非首租的会员信息");
        return false;
      }

      $("#renter_detail_guarantor_telphone").val(guarantor.mobile);
      $("#renter_detail_guarantor_name").val(guarantor.member_name);
      $("#renter_detail_guarantor_credentials_no").val(guarantor.id_number);
      $("#renter_detail_guarantor_credentials_type").val(guarantor.id_type);
      $("#renter_detail_guarantor_credentials_begin_at").val(guarantor.id_date_from);
      $("#renter_detail_guarantor_credentials_end_at").val(guarantor.id_date_to);
      sex = guarantor.sex == "male" ? "man" : "woman";
      $("#renter_detail_guarantor_gender").val(sex);

      $("#guarantor-begin-at").val(guarantor.id_date_from);
      $("#guarantor-end-at").val(guarantor.id_date_to);
      $("#guarantor-name").val(guarantor.member_name);
      $("#guarantor-no").val(guarantor.id_number);

    });
  });

  var show_err_span = function(error_msg){
    return '<span class="label label-danger" style="font-size: 100%">'+error_msg+'</span>';
  };

  var show_info_span = function(info){
    return '<span class="label label-info" style="font-size: 100%">'+info+'</span>';
  };

  if ($('#fileupload').length) {
    // https://github.com/blueimp/jQuery-File-Upload/wiki/Setup
    // https://github.com/blueimp/jQuery-File-Upload/blob/gh-pages/js/main.js
    // Initialize the jQuery File Upload widget:
    $('#fileupload').fileupload({
        url: '/document_images',
        type: "POST",
        maxNumberOfFiles: 20,
        // Enable image resizing, except for Android and Opera,
        // which actually support image resizing, but fail to
        // send Blob objects via XHR requests:
        disableImageResize: /Android(?!.*Chrome)|Opera/
            .test(window.navigator.userAgent),
        //  max 999k
        maxFileSize: 999000,
        acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
        // Error and info messages:
        messages: {
          "maxFileSize": "文件太大",
          "minFileSize": "文件太小",
          "acceptFileTypes": "不允许的文件类型",
          "maxNumberOfFiles": "文件数太多",
          "uploadedBytes": "上传字节超过了文件大小",
          "emptyResult": "空的文件上传结果"
        }
    });

    // Load existing files:
    $('#fileupload').addClass('fileupload-processing');
    if (credential_order_id) {
      $.ajax({
        url: '/document_images/order_credentials?order_id=' + credential_order_id,
        dataType: 'json',
        context: $('#fileupload')[0]
      }).always(function () {
        $(this).removeClass('fileupload-processing');
      }).done(function (result) {
        result = _.merge(result, { init: true })
        $(this).fileupload('option', 'done')
          .call(this, $.Event('done'), {result: result});
      });
    }

    // Custom submit parameter
    $('#fileupload').bind('fileuploadsubmit', function (e, data) {
      var select = data.context.find('select');
      formData = select.serializeArray();
      formData.push({
        name: "document_image[order_no]",
        value: credential_order_no
      });
      data.formData = formData;
    });

    // Custom upload finished
    $('#fileupload').bind('fileuploaddestroyed', function (e, data) {
      window.location.reload();
    });

    $('#fileupload').bind('fileuploadfinished', function (e, data) {
      if (data.result && !data.result.init) {
        window.location.reload();
      }
    });
  }

  $('#send_sms_captcha').click(function (e) {
    var btn = $(this);
    var data = {
      telphone: $('#renter_detail_telphone').val()
    }

    if (!(data.telphone && data.telphone.length == 11)){
      alert('承租人电话没有填写或者不合法');
      console.error('承租人电话没有填写或者不合法', data.telphone);
      return;
    }

    btn.button('loading')
    $.post('/captchas/send_take_car_captcha.json', data).done(function(data) {
      if (data.status === 'success') {
        $(btn).html("<b>60</b>秒后重新发送").addClass("send-ok");
        window.setTimeout(enable_send_zcode, 100)
      } else {
        btn.text('发送失败');
      }
    });
  });

  function enable_send_zcode(){
    window.clearTimeout(this.timeoutID);
    var _wait_time = $(".send-ok b").text();
    if (_wait_time > 0)
      _wait_time--;
    else{
      $('#send_sms_captcha').html("重新发送").removeClass("send-ok");
      $('#send_sms_captcha').removeClass("disabled");
      $('#send_sms_captcha').attr('disabled', false);
    }
    $(".send-ok b").text(_wait_time)
    window.setTimeout(enable_send_zcode, 1000)
  }
});
