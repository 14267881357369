document.addEventListener("turbolinks:load", function() {

  $("#expected_take_at").datetimepicker({

    stepping: 60
  });
  $("#expected_return_at").datetimepicker({

    stepping: 60
  });

  $("#q_expected_rent_date_include").datetimepicker({
    format: 'L'
  });

  var order_search = $(".datepicker", "#order_search");
  order_search.datetimepicker();
  //init all datepicker
  $("#change_time").on("click", function(){
    changeTime();
  });
  $("#pos_cnp").on("click", function(){
    pos_cnp();
  });
  $("#pos_cnp_reset").on("click", function(){
    pos_cnp_reset();
  });
  $("#pos_cnp_close").on("click", function(){
    window.location.reload();
  });
  limitDateRange("expected_take_at", "expected_return_at");

  $("#addition-modal").on("click", function(){
    $("#additions").modal("show");
    var order_id = $(this).attr("order-id"),
        url = "/orders/"+ order_id+ "/order_additions/available.json";
    $.getJSON(url, {timestamp: (new Date()).valueOf()}, function(additions){
      var addition_html = "";
      $.each(additions, function(index){
        var addition = additions[index];
        addition_html += '<tr>'+
          '<td>'+
            '<label class="checkbox">'+
              '<input name="good_ids" type="checkbox" style="margin-left:0px;" value="'+
                addition.good_id+'" />'+
            '</label>'+
          '</td>'+
          '<td>'+addition.good_name+'</td>'+
          '<td>'+addition.price+'</td>'+
        '</tr>';
      });

      $("tbody","#additions").text("").append(addition_html);
    });
  });

  $('.order_get_detail_price_data').on('click', function() {
    var url = $('.order_get_detail_price_data').attr('url');
    var detail_type = $('.order_get_detail_price_data').attr('detail_type');
    var target_place = $('.order-price-data-placeholder');
    asyn_fill(url, detail_type, target_place);
  });

  $('.order_get_detail_logs').on('click', function() {
    var url = $('.order_get_detail_logs').attr('url');
    var detail_type = $('.order_get_detail_logs').attr('detail_type');
    var target_place = $('.order-logs-placeholder');
    if (target_place.attr('loaded') == 'false') {
      asyn_fill(url, detail_type, target_place);
      target_place.attr('loaded', 'true');
    }
  });

  $('.order_get_detail_upload_attachment').on('click', function() {
    var url = $('.order_get_detail_upload_attachment').attr('url');
    var detail_type = $('.order_get_detail_upload_attachment').attr('detail_type');
    var target_place = $('.upload-attachment-placeholder');
    if (target_place.attr('loaded') == 'false') {
      asyn_fill(url, detail_type, target_place);
      target_place.attr('loaded', 'true');
    }
  });

  $('.order_get_detail_vehicle_examinations').on('click', function() {
    var url = $('.order_get_detail_vehicle_examinations').attr('url');
    var detail_type = $('.order_get_detail_vehicle_examinations').attr('detail_type');
    var target_place = $('.vehicle-examinations-placeholder');
    asyn_fill(url, detail_type, target_place);
  });

  $('.order_get_detail_sign_forms').on('click', function() {
    var url = $('.order_get_detail_sign_forms').attr('url');
    var detail_type = $('.order_get_detail_sign_forms').attr('detail_type');
    var target_place = $('.sign-forms-placeholder');
    asyn_fill(url, detail_type, target_place);
  });

  $('.order-get-detail-credit-feedbacks').on("click", function (e) {
    replaceCreditFeedbacks(e.target)
  })

  $('.order_take_return_car_records').on("click", function (e) {
    replaceTakeReturnCarRecords(e.target)
  })

  function replaceCreditFeedbacks(item) {
    var url = item.getAttribute('url')
    var detailType = item.getAttribute('detail_type')
    var targetPlace = $('.credit-feedbacks-placeholder')
    asyn_fill(url, detailType, targetPlace)
  }

  function replaceTakeReturnCarRecords(item) {
    var url = item.getAttribute('url')
    var detailType = item.getAttribute('detail_type')
    var targetPlace = $('.take-return-car-records-placeholder')
    asyn_fill(url, detailType, targetPlace)
  }

  setTimeout(function () {
    // 刷新后自动加载当前active tab
    $('#order-nav-tab > li.active > a').each(function (_, item) {
      if (item.className.indexOf('order-get-detail-credit-feedbacks') >= 0) {
        replaceCreditFeedbacks(item)
      }
    })
  }, 100)

  function asyn_fill(url, detail_type, target){
    $.ajax({
      method: 'GET',
      url: url,
      data: {detail_type: detail_type},
      success: function(data) {
        target.html(data);

        // 处理下载文件事件
        target.find('.download').on("click", function(){
          renderNewPageForDownload($(this))
        });
      }
    })
  };

  function toggleBadge(key, data) {
    var badge = $('.' + key)
    if (parseInt(data[key], 10) > 0) {
      badge.text(data[key])
      badge.show()
    } else {
      badge.hide()
    }
  }

  // 添加增值服务
  $("#add-addition").on("click", function () {
    var _this = $(this);
    // 获取选中增值服务
    var additions = $("input:checked", "#additions");
    if(additions.length == 0){
      $("#additions").modal("hide");
      return false;
    }

    addition_ids = $.map(additions, function(addition){
      return $(addition).val();
    });
    var order_id = $("#addition-modal").attr("order-id");
    var url = "/orders/"+order_id+"/order_additions.json";
    $.ajax({
      method: "POST",
      url: url,
      data:{addition_ids: addition_ids},
      beforeSend: function(){
        _this.addClass("disabled");
        _this.html("提交中...");
      },
      success: function(data){
        if (data.code === 1) {
          $("tbody", "#additions").html("");
          $("#additions").modal("hide");
          window.location.reload();
        }else if(data.code == 2){
          alert("增值服务不合法");
        }else{
          alert("出错了，请刷新重试。" + data.msg);
        }
      }
    });
  });

  // bind promotion checkbox event
  $('#promotions').on('change', ':checkbox', validate_and_update_order_price);

  $('#promotions').on('click', '.js_why_can_not_use', function() {
    params = $('#promotions :checkbox, #order-addition-list input:checked, #order_ota_channel_id').serialize();
    params += '&try_promotion_id=' + encodeURIComponent($(this).prev('input').val());

    $.ajax('/orders/try_promotion.json', { data: params }).done(function(data) {
      alert(data.try_fail_message)
    });

  });

  $(".addition_id").on("change", validate_and_update_order_price);
  $("#instance_discount #refesh_money").on("click", validate_and_update_order_price);

  function validate_and_update_order_price() {
    params = $('#promotions :input, #promotions :checkbox, #order-addition-list input:checked').serialize();
    if($("#order_ota_channel_id").length){
      params += '&ota_channel_id=' + encodeURIComponent($('#order_ota_channel_id').val());
    }
    $.ajax('/orders/filter_promotions.js', { data: params }).done(function() {
      _update_order_price();
    });
  }

  $("#order_ota_channel_id").on("change", validate_and_update_order_price);

  function _update_order_price() {
    "use strict";
    var checked_additions = $("input:checked","#order-addition-list");
    var addition_ids = $.map(checked_additions, function(addition){
      return $(addition).val();
    });
    var promotion_ids = $.map($('#promotions :checkbox').serializeArray(), function(item) {
      return item.value
    });
    var ota_channel_id = ''
    if($('#order_ota_channel_id').length){
      ota_channel_id = $('#order_ota_channel_id').val();
    }

    var instance_discount_money = $('#instance_discount :input').val();
    if (instance_discount_money) {
      if (!(isNumber(instance_discount_money) && instance_discount_money >= 0)) {
        alert('减免的金额不合法，需要是大于或等于0的数字');
        return;
      }
    }

    $.ajax({
      type: "GET",
      url: "/orders/compute_price.json",
      data: {
        addition_ids: addition_ids,
        promotion_ids: promotion_ids,
        instance_discount_money: instance_discount_money,
        ota_channel_id: ota_channel_id,
        timestamp: (new Date()).valueOf()
      },
      success: function(data){
        COM.loading_hide();
        $("#expected_amount").find("b").text(data.amount);
        if (data.amount < 0) {
          $('#create_order_btn').hide();
          alert("总金额不能小于0，请尝试取消部分优惠项");
        } else {
          $('#create_order_btn').show();
        }
      },
      error: function(xhr) {
        COM.loading_hide();
        $('#create_order_btn').hide();
        alert(xhr.responseJSON.error)
      }
    });

    $.ajax({
      type: "GET",
      url: "/orders/update_account_price_log",
      data: {
        addition_ids: addition_ids,
        promotion_ids: promotion_ids,
        instance_discount_money: instance_discount_money,
        ota_channel_id: ota_channel_id
      }
    });
  }

  // index search options show and hide
 $('.js-more-search').hide();

  $('#more_search').on("click", function(){
    "use strict";

    var that = $(this);
    if(that.data('shown') == 'true') {
      that.data('shown', 'false')
      that.text('更多+')
      $('.js-more-search').hide();
      $.removeCookie('order_search');
    } else {
      that.data('shown', 'true')
      that.text('收起-')
      $('.js-more-search').show();
      $.cookie('order_search', 'js-more-search', { expires: 7 });
    }
  });

  if ($.cookie('order_search')  != null){
     $('#more_search').on("click", );
   }

  initPairDate('q_expected_take_at_gteq', 'q_expected_take_at_lteq')
  initPairDate('q_expected_return_at_gteq', 'q_expected_return_at_lteq')
  initPairDate('q_actual_take_at_gteq', 'q_actual_take_at_lteq')
  initPairDate('q_actual_return_at_gteq', 'q_actual_return_at_lteq')
  initPairDate('q_order_relet_logs_created_at_gteq', 'q_order_relet_logs_created_at_lteq', { stepping: 60 })

});

//修改取还车时间
function changeTime(){
  order_id = $("#order_id").val();
  $.ajax({
    type: "JSON",
    method: "POST",
    url: "/orders/" + order_id + "/change_time",
    data: $("#change_time_form").serialize(),
    beforeSend: function(){
      $("#change_time").off('click');
      $("#change_time").html("提交中...");
    },
    success: function(data){
      $('#change_time').on('click', changeTime);
      $("#change_time").html("确认");
      $("#change_time_error").hide();
      if(data.result){
        if (data.message)
        {
            alert("操作成功.\n\r" + data.message);
        }
        window.location.reload();
      }else{
        $("#change_time_error").html(data.message);
        $("#change_time_error").show();
      }
    }
  });
}

//电话支付
function pos_cnp(){
  $.ajax({
    type: "JSON",
    method: "POST",
    url: '/pays/pos_cnp',
    data: $("#pos_cnp_form").serialize(),
    beforeSend: function(){
      $("#pos_cnp").off('click');
      $("#pos_cnp").html("提交中...");
    },
    success: function(data){
      $('#pos_cnp').on('click', pos_cnp);
      $("#pos_cnp").html("确认");
      $("#pos_cnp_error").hide();
      if(data.response_code == "C0" || data.response_code == "00"){
        $("#pos_cnp").hide();
        $("#pos_cnp_reset").show();
        $("#pos_cnp_error").html("正在处理中...等待客户短信确认，系统会自动入账");
        $("#pos_cnp_error").show();
      }else{
        $("#pos_cnp_error").html(data.message);
        $("#pos_cnp_error").show();
      }
    }
  });
}

function pos_cnp_reset(){
  $("#pos_cnp").show();
  $("#pos_cnp").html("确认");
  $("#pos_cnp_reset").hide();
  $("#pos_cnp_error").hide();
}

function initPairDate(startAt, endAt) {
  var config = {  stepping: 60 }
  if (arguments[2]) { config = arguments[2] }
  $("#" + startAt).datetimepicker(config);
  $("#" + endAt).datetimepicker(config);
  limitDateRange(startAt, endAt);
}

// 限制日期范围
function limitDateRange(startAt, endAt){
  $("#" + startAt).on("dp.change",function (e) {
       $('#' + endAt).data("DateTimePicker").options({minDate: e.date});
  });
  $("#" + endAt).on("dp.change",function (e) {
     $('#' + startAt).data("DateTimePicker").options({maxDate: e.date});
  });
}

// 保存订单承租人及预定人信息
function get_rent_detail_and_booking_member_info(){
  var info = {
    // 承租人信息
    renter_detail: {
      name: $('#order_renter_detail_attributes_name').val(),
      telphone: $('#order_renter_detail_attributes_telphone').val(),
      credentials_type: $('#order_renter_detail_attributes_credentials_type').val(),
      credentials_no: $('#order_renter_detail_attributes_credentials_no').val()
    },
    // 预定人信息
    booking_member: {
      booking_by: $('#order_booking_by').val(),
      booking_tel: $('#order_booking_tel').val(),
      booking_credentials_type: $('#order_booking_credentials_type').val(),
      booking_credentials_no: $('#order_booking_credentials_no').val()
    }
  }
  var info_params = jQuery.param(info)
  var link_to_tabs = $('.change_order_button');
  if (link_to_tabs.length > 0){
    $.each(link_to_tabs, function(index, tab){
      var new_href = tab.href + '&' + info_params
      tab.href = new_href
    })
  }
}

function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
