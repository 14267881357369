import React from 'react';
import FormGroup from "components/bill_request/FormGroup"

export default class OffsetItemSelector extends React.Component {

  constructor(...args) {
    super(...args);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const index = event.target.value;
    console.log(this, this.props, this.props);
    return this.props.update_offset_reason(index);
  }

  render() {
    const _self = this;
    const group = _.map(this.props.offsetItems, category_array => {
      return <option key={category_array.id} value={category_array.id}>{category_array.name}</option>;
    });

    const select_style = {
      width: 447,
      display: 'inline-block',
      marginRight: 20
    };

    return <FormGroup id='offsetCategorySelector' label='费用类型'>
      <select id='offsetCategorySelector' className='form-control' style={select_style}
      ref='offsetCategorySelector' onChange={this.handleChange}> {group} </select>
    </FormGroup>;
  }
}
