import React from 'react';
import ReactDOM from 'react-dom';

import FormGroup from "components/bill_request/FormGroup"

export default class OtherConsumeSelector extends React.Component {

  constructor(...args) {
    super(...args);

    this.addConsume = this.addConsume.bind(this);
  }

  addConsume(event) {
    const code = ReactDOM.findDOMNode(this.refs.otherConsumeSelector).value;
    if (code == "") {
      return
    }
    const {
      money
    } = this.props.otherConsumeMoney[code];
    const consume = {};
    consume[code] = money;
    return this.props.add_other_bill_requests(consume);
  }

  render() {
    const _self = this;
    const group = _.map(this.props.otherConsumeMoney, (info, code) => {
      return <option key={code} value={code}>{_self.props.financeItems[code].name} 共{info.money}元</option>;
    });

    const select_style = {
      width: 447,
      display: 'inline-block',
      marginRight: 20
    };

    return <FormGroup id='otherConsumeSelector' label='其他消费'>
      <select id='otherConsumeSelector' className='form-control' style={select_style} ref='otherConsumeSelector'> {group} </select>
      <button type='button' className='btn btn-info' onClick={this.addConsume}>添加</button>
    </FormGroup>;
  }
}
