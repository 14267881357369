import React from 'react';
export default class FormGroup extends React.Component {
  render() {
    return <div className='form-group'>
      <label className="col-sm-2 control-label" htmlFor={this.props.id}> {this.props.label} </label>
      <div className="col-sm-5">
        {this.props.children}
      </div>
      <div className="col-sm-5" style={{paddingTop: 7}}>
        {this.props.hint || ''}
      </div>
    </div>;
  }
}
