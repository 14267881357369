import "jquery-ui"
import "jquery-ui/ui/widgets/autocomplete"
import 'jquery-ui/themes/base/all.css'

import "bootstrap"
import "@coreui/coreui"

import "@coreui/icons/sprites/brand.svg"
import "@coreui/icons/sprites/flag.svg"
import "@coreui/icons/sprites/free.svg"

import "global/index"

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
