(function() {
  var COM;

  COM = {
    _ispassword: '/^[a-zA-Z0-9_]{6,18}$/',
    _isname: '/^[\u4e00-\u9fa5]{2,9}$/',
    _isEmail: '/([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)/',
    _isPhone: '/^((\(\d{2,3}\))|(\d{3}\-))?(13|15|18|14)\d{9}$/',
    _isIdcard: '/^([\d]{18,18})|([\d]{17,17}[xX]{1,1})$/',
    onload_datetime_ini: function() {
      var lease;
      lease = $(".datepicker", ".search-car-status");
      lease.datetimepicker();
      lease.on("dp.change", function(e) {});
    },
    loading_show: function() {
      $('<div id="loading" class="loading">页面正在努力加载中...</div>').appendTo('body');
    },
    loading_show_on_div: function(div, message) {
      $("<div id='loading' class='loading'>" + message + "</div>").appendTo(div);
      $("<div id='loading-block' class='loading-block'></div>").appendTo(div);
    },
    loading_hide: function() {
      $("#loading,#loading-block").remove();
    },
    collapse_sidebar: function() {
      $('[data-click=sidebar-minify]').trigger("click");
      $("#real_car").css("left", "75px");
    }
  };

  window.COM = COM;

}).call(this);
