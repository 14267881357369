import React from 'react';
import FormGroup from "components/bill_request/FormGroup"

export default class BillRequstItem extends React.Component {

  constructor(...args) {
    super(...args);

    this.onChange = this.onChange.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  onChange(event) {
    const input = $(event.target);
    const code = input.attr('name');
    const money = +input.val();

    const bill_request = {};
    bill_request[code] = money;
    return this.props.update_bill_requests(bill_request);
  }

  removeItem(event) {
    const code = $(event.target).attr('name');
    return this.props.removeItemWithButton(code);
  }

  render() {
    const _self = this;
    const group = _.map(this.props.data, (money, code) => {
      return <FormGroup key={code} label={_self.props.financeItems[code].name} hint={'可冲减的最大金额为' + _self.props.code_max_money[code]}>
        <div className='input-group'>
          <input type="number" name={code} value={money} onChange={_self.onChange} className='form-control' />
          <span className="input-group-btn">
            <button type='button' className='btn btn-info' onClick={_self.removeItem} name={code}>删除</button>
          </span>
        </div>
      </FormGroup>;
    });

    return <div>{group}</div>;
  }
}
