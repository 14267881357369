var DisabledCarStatuses = {
  init: function() {
    $("#batch_edit_date_range #beginAt").datetimepicker({
      format: 'L'
    });
    $("#batch_edit_date_range #endAt").datetimepicker({
      format: 'L'
    });
    $(".js_edit_date_range_btn").on('click',function() {
      var disabled_car_status_ids;
      disabled_car_status_ids = $('[name="ids[]"]:checked');
      if (disabled_car_status_ids.length > 0) {
        return $("#batch_edit_date_range").modal("show");
      } else {
        return alert("请选中要修改的记录");
      }
    });
    $("#js_edit_date_range").on('click', function() {
      var params;
      params = {
        ids: DisabledCarStatuses.get_selected_ids(),
        begin_at: $('#beginAt').val(),
        end_at: $('#endAt').val()
      };
      if (params.begin_at === "") {
        alert('请选择开始日期');
        return false;
      }
      if (params.end_at === "") {
        alert('请选择结束日期');
        return false;
      }
      return DisabledCarStatuses.do_action('#batch_change_date_range', '/disabled_car_statuses/batch_change_date_range', params);
    });
    return $(".js_accomplish").on('click',function() {
      var params;
      params = {
        ids: DisabledCarStatuses.get_selected_ids()
      };
      if (params.ids.length <= 0) {
        alert('请选中车辆');
        return false;
      }
      return DisabledCarStatuses.do_action('#accomplish_disabled_car_status', '/disabled_car_statuses/accomplish_disabled_car_status', params);
    });
  },
  do_action: function(action, url, params) {
    return $.post(url, params).done(function(data) {
      if (data.status) {
        alert('操作成功');
        return window.location.reload();
      } else {
        return alert("操作失败: " + data.message);
      }
    });
  },
  get_selected_ids: function() {
    return $('[name="ids[]"]:checked').map(function() {
      return this.value;
    }).get();
  }
};

$(function() {
  if ($('body').attr('data-controller') === 'disabled_car_statuses') {
    return DisabledCarStatuses.init();
  }
});
