import React from 'react';
export default class PriceBoxGroup extends React.Component {
  constructor(...args) {
    super(...args);

    this.is_checked = this.is_checked.bind(this);
  }

  is_checked(day) {
    return _.includes(this.props.checked_dates, day);
  }

  render() {
    const _self = this;
    const priceBoxGroup = _.map(this.props.data, (codeMoney, day) => {
      const priceBox = _.map(codeMoney, (money, code) => {
        return <div key={code}>{_self.props.financeItems[code].name} {money}元</div>;
      });

      return <div  key={day} className={_self.props.columnClass} className='col-md-3'>
        <div className="panel panel-success">
          <div className="panel-body">
              <b>{day}</b>
              {priceBox}
              <label className='checkbox-inline' style={{display: 'block'}}>
                <input type="checkbox" className='checkbox-fix' value={day} checked={_self.is_checked(day)}
                  onChange={_self.props.onCheckboxChange} /> 选中
              </label>
          </div>
        </div>
      </div>;
    });

    return <div>{priceBoxGroup}</div>;
  }
}
