import React from 'react';
import { Typography, Tag } from 'antd';

export default function StateTips(props) {
  const {stateMapping} = props

  const tags = () => {
    var st = []
    for (var state in stateMapping) {
      var s = stateMapping[state]
      if (s.except) {
        continue
      }
      st.push(<Tag color={s.color} key={state}>{s.human}</Tag>)
    }
    return st
  }
  return (
    <Typography className='state-layout'>
      {tags()}
    </Typography>
  )
}
