document.addEventListener("turbolinks:load", function() {

  $("#renter_detail_credentials_type").change(function(){
    if (can_manual_renter){return false;}
    var changed_value = $(this).val(),
        hide_fields = [" input"],
        renter_fields = ["name", "credentials_no", "credentials_begin_at", "credentials_end_at"];

    if(changed_value != "身份证" && !can_input_letter){
      operate_take_car_fields("disabled", ".hidden_renter_field", hide_fields);
      operate_take_car_fields("", "#renter_detail_", renter_fields);
    }else{
      operate_take_car_fields("", ".hidden_renter_field", hide_fields);
      operate_take_car_fields("disabled", "#renter_detail_", renter_fields);
    }

    if((changed_value === '护照' && can_input_passport) ||
       (changed_value === '回乡证' && can_input_home_visit)){
      operate_take_car_fields("", "#renter_detail_", renter_fields);
      operate_take_car_fields("", ".renter_detail_", renter_fields);
      operate_take_car_fields("disabled", ".hidden_renter_field", hide_fields);
    }

    renter_fields.forEach(function(item, _) {
      $("#renter_detail_"+item).val("");
    });

    $('.datetimepicker:not(:disabled)').datetimepicker({format: 'L'});
  });

  function operate_take_car_fields(operate_type, dom_prefix, fields){
    fields.forEach(function(item, _) {
      field_dom = $(dom_prefix + item);
      if(operate_type == "disabled"){
        field_dom.attr("disabled", "disabled");
      }else{
        field_dom.removeAttr("disabled");
        field_dom.removeClass("disabled");
      }
    });
  }

  $('.bwizard-steps').on('click', '.filled', function() {
      window.location.href = window.location.pathname + '?step=' + $(this).data('stepName');
  });

});
