import JSONEditor from "jsoneditor"
window.addJSONeditor = function(editor_id, hidden_editor_id, json) {
  var container = document.getElementById(editor_id);
  var real_container = document.getElementById(hidden_editor_id);
  var options = {
    mode: "code",
    modes: ['code', 'tree', 'view'], // allowed modes
    statusBar: false,
    onError: function (err) {
      alert(err.toString());
    },
    onChangeText: function (text) {
      console.log(text);
      real_container.value = text;
    }
  };
  var editor = new JSONEditor(container, options, json)
  editor.aceEditor.setOptions({ maxLines: Infinity })
}

var helper = {
  field_type_string: 'select.js_field_type',
  code_string: 'select.js_code',
  data_string: '.js_data',
  hidden_data_string: '.js_hidden_data',
  not_string: '.js_not',
  get_template_string: '.js_get_template',
  translate_template_string: '.js_translate_template',
  near_item:
    function(selector, type) {
      var near = $(selector).closest('.js_promotion_field').find(type)
      if (near.length > 0) {
        return near
      } else {
        return $(type)
      }
    }
}

document.addEventListener("turbolinks:load", function() {
  $('body').on('change', helper.field_type_string, get_fields_type);
  $('body').on('change', helper.code_string, show_not_string);
  $(helper.field_type_string).trigger('change');
  $(helper.code_string).trigger('change');
  $('body').on('click', helper.get_template_string, get_default_template);
  $('body').on('click', helper.translate_template_string, translate_template);
});

function translate_template() {
  var that = this;
  var paramData = {
    code: helper.near_item(that, helper.code_string).val(),
    data: helper.near_item(that, helper.hidden_data_string).val()
  };

  try {
    paramData.data = JSON.parse(paramData.data)
  } catch (e) {
    alert('不是正确的JSON格式:' + e.message)
    return
  }

  $.ajax({
    type: "JSON",
    method: "GET",
    url: "/promotion_fields/translate",
    data: paramData,
    success: function(data){
      helper.near_item(that, helper.data_string).after('<br/><pre>' + data.result + '</pre>');
    }
  })

}

function get_fields_type(){
  var that = this;

  var field_type = $(that).val();
  if(field_type == "condition")
  {
    helper.near_item(that, helper.not_string).show();
  }else{
    helper.near_item(that, helper.not_string).hide();
  }

  $.ajax({
    type: "JSON",
    method: "GET",
    url: "/promotion_fields/fields_type",
    data: {field_type: field_type},
    success: function(data){
      var near_code = helper.near_item(that, helper.code_string);
      var pre_selected = near_code.val();

      var option_html = $.map(data, function(key, value) {
        if (pre_selected == value) {
          return "<option value='"+ value + "' selected='selected'>"+ key +"</option>"
        } else {
          return "<option value='"+ value + "'>"+ key +"</option>"
        }
      }).join('');

      near_code.html(option_html).trigger('change');
    }
  });
}

function show_not_string(){
  var that = this;
  var not_val = $(that).val();
  var current_code = helper.near_item(that, helper.code_string).attr('id');
  var current_not = current_code.replace('code', 'not');
  if (not_val === "MulTypeStoreCondition") {
    $("#"+current_not).attr('disabled', true)
  } else {
    $("#"+current_not).attr('disabled', false)
  }
}

function get_default_template() {
  var that = this;

  $.ajax({
    type: "JSON",
    method: "GET",
    url: "/promotion_fields/default_template",
    data :{code: helper.near_item(that, helper.code_string).val()},
    success: function(data){
      var template;
      if (data._string_template) {
        template = data._string_template
      } else {
        template = JSON.stringify(data, null, ' ')
      }
      helper.near_item(that, helper.data_string).nextAll().remove();
      helper.near_item(that, helper.data_string).after('<br/><pre>' + template + '</pre>');
    }
  })
}
