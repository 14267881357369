import React from 'react';
import StateTable from "components/car_status/components/StateTable"
import Search from "components/car_status/components/Search"
import StateTips from "components/car_status/components/StateTips"
import {ppHOC} from "components/Global"
import moment from 'moment'

class NewCarStatus extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // 要和Search中的defaultValues相对应
      params: {
        begin_at: moment().format('YYYY-MM-DD'),
        end_at: moment().add(7, 'days').format('YYYY-MM-DD 23:59:59'),
        car_category_id: '',
        store_id: props.store_list[0][1],
        order_no: '',
        car_no: '',
      },
      priceList: [],
    };
  }

  componentDidMount() {
    this.setPriceData()
  }

  async setPriceData() {
    var respond_data = await this.getPriceData();
    this.changePriceData(respond_data.data);
  }

  getPriceData() {
    return $.ajax({
      url: '/vehicle_states/feature_status',
      data: this.state.params,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      type: 'POST'
    });
  }

  onClickConfirm(parent) {
    return function (params) {
      parent.setState({params: params})
      parent.setPriceData()
    }
  }

  changePriceData(data) {
    this.setState({ priceList: data})
  }

  stateMapping() {
    return {
      0: {color: '#ffffff', human: '可预订', except: true},
      1: {color: '#339966', human: '支付中'},
      2: {color: '#d966ff', human: '待出行'},
      3: {color: '#ff6666', human: '已取车'},
      5: {color: '#510066', human: '已停用'},
      999: {color: '#000000', human: '冲突'},
    }
  }

  render() {
    const days = this.state.priceList[0] ? this.state.priceList[0].days : []
    const mappings = this.stateMapping()
    return (
      <>
        <Search
          onClickConfirm={this.onClickConfirm(this)}
          searchParams={this.state.params}
          {...this.props}
        />
        <StateTips stateMapping={mappings} />
        <StateTable priceList={this.state.priceList} days={days} stateMapping={mappings}/>
      </>
      )
  }
}

export default ppHOC(NewCarStatus)
