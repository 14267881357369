// localStorage functions

// arguments[2]为过期小时
export function localSave(key, data) {
  if (!window.localStorage) return false
  // 默认过期时间为1000小时
  var expirationHour = 1000
  if (arguments[2]) { expirationHour = arguments[2] }

  var expirationMS = expirationHour * 3600 * 1000
  var record = { data: data, timestamp: new Date().getTime() + expirationMS }

  localStorage.setItem(key, JSON.stringify(record))
}

export function localLoad(key) {
  if (!window.localStorage) return false

  var record = JSON.parse(localStorage.getItem(key))
  if (!record) return false
  if (new Date().getTime() > record.timestamp) {
    localStorage.removeItem(key)
    return false
  }
  return record.data
}

export function localRemove(key) {
  if (!window.localStorage) return false
  localStorage.removeItem(key)
}
