// 手动收款模式
window.payWithManualMode = function(){
  var reference_no = $("#reference_no").val();
  if($.trim(reference_no) == ""){
      $("#manual_mode_warning").html("请输入[交易单号]");
      $("#manual_mode_warning").show();
      return;
  }
  $.ajax({
    method: "POST",
    type: "JSON",
    url: "/pays/manual_mode",
    data: $("#pay_with_manual_mode").serialize(),
    beforeSend: function(){
      $("#pay_with_manual_button").attr('disabled', true);
    },
    success: function(data){
      if(data.success){
        window.location.reload();
      }else{
        $("#manual_mode_warning").html(data.message);
        $("#manual_mode_warning").show();
        timeout = setTimeout(hideManualMode, 5000);
      }
    },
    error: function(){
      $("#manual_mode_warning").html("对账错误，请稍后再试！");
      $("#manual_mode_warning").show();
      timeout = setTimeout(hideManualMode, 5000);
    }
  });
}

window.hideManualMode = function(){
  $('#manual_mode_warning').hide();
  clearTimeout(timeout);
  $("#pay_with_manual_button").attr('disabled', false);
}

window.changeDepositAmount = function(){
  $("#set-payment").show();
  $("#payment-table").hide();
}

// 付款码支付
window.qrcodePayMode = function(code){
  var pay_type = $("#pay_type").val();
  var id = $("#order_id").val();
  var due_amount = $("#due_amount").val();
  $.ajax({
    method: "POST",
    type: "JSON",
    url: "/payments/order_pay",
    data: {id: id, pay_type: pay_type, token: code, due_amount: due_amount},
    success: function(data){
      if(data.success == true){
        window.location.reload();
      }else{
        $("#qrcode_pay_warning").html(data.message);
        $("#qrcode_pay_warning").show();
        timeout = setTimeout(hideQrcodeMode, 5000);
      }
    },
    error: function(){
      $("#qrcode_pay_warning").html("支付出错，请稍后重试！");
      $("#qrcode_pay_warning").show();
      timeout = setTimeout(hideQrcodeMode, 5000);
    }
  });
}

window.hideQrcodeMode = function(){
  $('#qrcode_pay_warning').hide();
  clearTimeout(timeout);
  $(".qrcode_gateway").attr('disabled', false);
}
