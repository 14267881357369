import React from 'react';
import { Table, Tooltip } from 'antd';

import moment from 'moment'

const fixedColumns = [
  {
    title: '车牌',
    dataIndex: 'car_no',
    key: 'car_no',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 60,
    align: 'center',
  },
  {
    title: '车型',
    dataIndex: 'car_category_name',
    key: 'car_category_name',
    width: 80,
    align: 'center',
    ellipsis: {
      showTitle: false,
    },
    render: name => (
      <Tooltip placement="topLeft" title={name}>
        {name}
      </Tooltip>
    ),
  },
];

const timeMapping = {
  '09:00': '早',
  '17:00': '中',
  '23:59': '晚',
}

export default function StateTable(props) {
  const {stateMapping} = props
  const renderDetail = (date, time) => {
    return (value, row, index) => {
      const state = row.dates.find(d => {
        return d.begin_time === time && d.date === date
      })

      const color = stateMapping[state.status] ? stateMapping[state.status].color : ''

      return {
        children: <span className={`state${state.status}`}/>,
        props: {
          rowSpan: 1,
          style: {
            backgroundColor: color
          }
        },
      };
    };
  }

  const days = props.days.map(v => {
    return {
      title: moment(v.date).format("MM-DD"),
      children: v.hours.map(h => {
        return {
          title: '',
          width: 50,
          render: renderDetail(v.date, h),
        }
      })
    }
  })
  const prices = props.priceList.map((v, i) => {
    return Object.assign(v, {
      key: i,
      index: i+1
    })
  })

  const columns = prices.length > 0 ? fixedColumns.concat(days) : []

  return (
    <Table
      pagination={false}
      className='state-layout'
      columns={columns}
      dataSource={prices}
      bordered
      sticky
      size="middle"
      scroll={{ x: 'calc(700px + 50%)' }}
    />
  )
}
