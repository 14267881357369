import moment from "moment"
import {showModal} from "justgo/modal"

document.addEventListener("turbolinks:load", function() {
  "use strict";

  var config = {
    "title": "选择门店",
    "url": "/stores/load",
    "is_radio": false,
    "head_names": ["名称", "门店类型"],
    "fields": ["name", "store_type"],
    paginate: true,
    "search_fields": [
      {"label": "城市包含", "name": "city_area_cont" },
      {"label": "名称包含", "name": "name_cont"},
      {"label": "门店类型", "name": "store_type_cont"}
    ]
  };

  $('.js-store-select').each(function() {
    var target = $(this);
    var options = $.extend( {}, config, { 'id': target.attr('id') },
      target.data('storeSelect') );

      JustgoChoose.init(options);
  });

  moment.updateLocale('zh-cn', {
    longDateFormat: {
      LT: 'HH:mm',
      L: 'YYYY-MM-DD',
      M: 'YYYY-MM'
    }
  });

  $('.searchbox-datetime').datetimepicker();
  $('.searchbox-date').datetimepicker({
    format: 'L'
  });
  $('.searchbox-month').datetimepicker({
    format: 'M'
  });

  initDatepicker();
  $(".datepicker").datetimepicker();

  $('body').on('click', '.my_modal', function (){
    showModal(this);
  });

  // 全选和反选
  init_check_all_checkbox();
});

window.initDatepicker = function(options) {
  var getOption = function(jq) {
    if (typeof jq == 'string') {
      jq = $(jq)
    }

    var data_options = jq.data("datepicker") || {};
    if (options) {
      return $.extend(data_options, options)
    } else {
      return data_options
    }
  }

  var picker = $('.js_datepicker');
  var start_picker = $('.js_datepicker_start');
  var end_picker = $('.js_datepicker_end');

  if (picker.length > 0) {
    picker.datetimepicker(getOption(picker))
  }

  if (start_picker.length > 0 && end_picker.length > 0) {
    var start_option = getOption(start_picker)
    start_picker.datetimepicker(getOption(start_picker))
    end_picker.datetimepicker(getOption(end_picker))
    if (start_option.pickDate == false) {
          // do nothing
    }else {
     start_picker.on("dp.change",function (e) {
        end_picker.data("DateTimePicker").options({minDate: e.date});
      });
      end_picker.on("dp.change",function (e) {
         start_picker.data("DateTimePicker").options({maxDate: e.date});
      });
    }

  }
}

window.ConfirmAction = function(msg, fn){
  if(msg === "") return;

  function cfm(){
    if(confirm(msg)){
      fn()
    }
  }
  setTimeout(cfm, 1000)
}

window.submit_json_to_url = function(id, form_id, url, action){
  var button_id = 'my_'+ id + '_button';
  var my_method = (typeof(action)=="undefined")? "POST" : action;
  var formData = new FormData($("#" + form_id)[0]);
  $.ajax({
    type: "JSON",
    method: my_method,
    async: false,
    cache: false,
    contentType: false,
    processData: false,
    url: url,
    data: formData,
    beforeSend: function(){
      $('#'+ button_id).prop('disabled', true);
      $('#'+ button_id).html("提交中...");
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
      $('#'+ button_id).prop('disabled', false);
      $('#'+ button_id).html("确认");
      alert(XMLHttpRequest.status + errorThrown);
    },
    success: function(data){
      $('#'+ button_id).html("确认");
      $("#my_" + id + '_error').hide();
      if(data.result){
        var message = "操作成功, 确认后返回\n\r"
        if(data.message)
        {
          message += data.message;
        }
        alert(message);
        window.location.reload();
      }else{
        $('#'+ button_id).html("确认");
        $('#'+ button_id).prop('disabled', false);
        $("#my_" + id + '_error').html(data.message);
        $("#my_" + id + '_error').show();
      }
    }
  });
}

window.create_store_operation_log = function(obj) {
  var operation_id = $(obj).attr("operation_id");
  var operation_type = $(obj).attr("operation_type");
  $.ajax({
    type: "POST",
    url: '/store_operation_logs/create_operation_log',
    dataType: 'json',
    data: {'operation_type': operation_type, 'operation_id': operation_id},
    success: function(response_data) {
    }
  });
}

window.check_electric_input = function(obj){
  var electric = parseInt(obj.value);
  if (electric < 0 || electric > 100) {
    obj.value = '100';
    alert("电量应该在0-100之间!");
  }
}

window.create_vehicle_examination = function(obj) {
  var operation_id = $(obj).attr("operation_id");
  var operation_type = $(obj).attr("operation_type");
  var car_id = $(obj).attr("car_id");
  var user_id = $(obj).attr("user_id");
  if (window.ReadCardForBlueTooth) {
    if(confirm('确定进入验车环节?')) {
      window.ReadCardForBlueTooth.createExamination(operation_type, operation_id, car_id, user_id);
    }
  } else {
    alert("暂只支持app验车");
  }
}

document.addEventListener("turbolinks:load", function() {
  //初始化车辆状态
  init_car_status();
});

function init_car_status(){
  if($(".associate_status").length <= 0){
    return;
  }
  $.ajax({
    method: "get",
    url: "/cars/load_status_tree.json",
    success: function(data){
      bind_change_status(data);
    }
  });
}

function bind_change_status(status_json){
  //绑定车辆状态--实时状态
  if($(".associate_status")){
    $("#car_status").on('change', function(){
      car_status_val = $(this).val();
      //设置实时状态
      var real_status = $("#car_real_status");
      real_status.html("");
      real_status.append("<option value=''>请选择实时状态</option>");
      var status_detail = $("#car_status_detail");
      status_detail.html("");
      status_detail.append("<option value=''>请选择状态明细</option>");
      $.each(status_json, function(index, status){
        if(status.key == car_status_val){
          $.each(status.children, function(index2, target0){
            real_status.append("<option value='" + target0.key + "'>" + target0.value + "</option>");
            $.each(target0.children, function(index3, target){
              status_detail.append("<option value='" + target.key + "'>" + target.value + "</option>");
            });
          });
        }
      });
    });
  }

  // 绑定实时状态--状态明细关联
  $("#car_real_status").on('change', function(){
    car_status_val = $("#car_status").val();
    real_status_val = $(this).val();
    status_detail = $("#car_status_detail");

    //清空选项
    status_detail.html("");
    status_detail.append("<option value=''>请选择状态明细</option>");
    $.each(status_json, function(index, status){
      if(status.key == car_status_val){
        $.each(status.children, function(index2, real_status){
          if(real_status.key == real_status_val){
            $.each(real_status.children, function(index3, target){
              status_detail.append("<option value='" + target.key + "'>" + target.value + "</option>");
            });
          }
        });
      }
    });
  });
}


function init_check_all_checkbox() {
  let prefix = "data-uno-";
  let name;
  name = prefix + "revcheck";
  $("[" + name + "]").on("change", function () {
    var btn, target_name;
    btn = $(this);
    target_name = btn.attr(name);
    console.log(name, target_name);
    $("[name='" + target_name + "']").each(function (index, item) {
      console.log(item);
      item.checked = !item.checked;
      return true;
    });
    return true;
  });
  name = prefix + "checkall";
  $("[" + name + "]").on("change", function () {
    let btn, status, target_name, targets;
    btn = $(this);
    target_name = btn.attr(name);
    status = !!btn.prop('checked');
    targets = $("[name='" + target_name + "']");
    targets.each(function (index, item) {
      item.checked = status;
      return true;
    });
    return true;
  });
}
