
import {localSave, localLoad, localRemove} from "utils/localstorage.js"

var $BODY = $('body'), $SIDEBAR_MENU = $('#sidebar-menu');

function loadMenuData(saveKey, callback) {
  var data = localLoad(saveKey)

  if (data) {
    callback(false, data)
    return
  }

  $.getJSON(
    '/welcome/load_menu.json',
    {},
    function (menuJson) {
      // 存储12个小时
      localSave(saveKey, menuJson, 12)
      callback(true, data)
    }
  )
}

function removeMenuData(saveKey) {
  localRemove(saveKey)
}

function getMenuStoreKey() {
  var menuHtmlTag = 'v4menu';
  var traceId = $('meta[name="traceuser"]').attr('content');
  return [menuHtmlTag, traceId].join('/')
}

function renderMenu(refresh) {
  var key = getMenuStoreKey();

  if (refresh) {
    removeMenuData(key)
  }

  var menuHtml = []
  loadMenuData(key, function (refreshed, data) {
    menuHtml.push('<ul id="menu-list" class="nav nav-pills">')
    $.each(data, function (idx, resource) {
      var childMenu = []
      $.each(resource.children, function (idx, menu) {
        var url = menu.url
        if(menu.domain_url) {
          url = menu.domain_url
        }
        childMenu.push(`<a href="${url}" class="dropdown-item">${menu.name}</a>`)
      })
      menuHtml.push(`
        <li role="presentation" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            ${resource.name}
          </a>
          <div class="dropdown-menu scrollable-menu">
              ${childMenu.join("\n")}
          </div>
        </li>
      `)
    })
    menuHtml.push('</ul>')
    if (refreshed) {
      triggerRenderMenuLater();
    }
  })

  $('.menu-section').find('ul').remove();
  $('.menu-section').append(menuHtml.join("\n"))
  // $SIDEBAR_MENU.animate({ scrollTop: 0 }, "fast");
}

function bindReloadMenu() {
  $('#reload-layout-menu').on('click', function (e) {
    renderMenu(true);
    e.preventDefault();
    triggerRenderMenuLater();
  })
}

function triggerRenderMenuLater() {
  setTimeout(function () {
    renderMenu(false);
    initQuickJump();
  }, 3000)
}

function setTriggerUnlessExist(params) {
  if ($('.menu-section').find('ul')) {
    return
  }
  triggerRenderMenuLater();
}

function bindMenuSwitch() {
  var pageHabitTag = 'pagehabit';

  var useSM = function () {
    return localLoad(pageHabitTag)
  }

  var setSM = function (v) {
    localSave(pageHabitTag, v)
  }

  $('#layout-menu-switch').on('click', function () {
    if ($BODY.hasClass('nav-md')) {
      $SIDEBAR_MENU.find('li.active ul').hide();
      $SIDEBAR_MENU.find('li.active').addClass('active-sm').removeClass('active');
      setSM(true);
    } else {
      $SIDEBAR_MENU.find('li.active-sm ul').show();
      $SIDEBAR_MENU.find('li.active-sm').addClass('active').removeClass('active-sm');
      setSM(false);
    }

    $BODY.toggleClass('nav-md nav-sm');
  });

  if (useSM()) {
    $('#layout-menu-switch').trigger('click');
  }
}

// 页面挂起功能
function initPageHangUp() {
  $('#hang_up').on('click', function(){
    var title = $("title").text().split('|');
    $.ajax({
      url: '/welcome/push_paging_page',
      method: "post",
      data:{hang_up_url: window.location.href, title: title[0]},
      success: function(data){
        if (data.status == 'ok'){
          alert("页面挂起成功！")
        }
      }
    })
  })
}

function initLinkDiffEnable() {
  var toggleSwitch = function () {
    if (localLoad('enableLinkDiff')) {
      $('#linkDiffEnable').text('跟踪查询(当前: 开)')
    } else {
      $('#linkDiffEnable').text('跟踪查询(当前: 关)')
    }
  }

  toggleSwitch()

  $('#linkDiffEnable').click(function (e) {
    e.preventDefault()

    localSave('enableLinkDiff', !localLoad('enableLinkDiff'))
    toggleSwitch()
  })
}

function initQuickJump() {
  var dom = $('#top-quick-jump');
  if (!dom) {
    return
  }

  var data = localLoad(getMenuStoreKey());

  $.each(data, function (idx, resource) {
    if (!resource.children) {
      return
    }
    $.each(resource.children, function (idx, res) {
      dom.append(`<option value='${res.url}'>${res.name}</option>`);
    })
  })
  dom.select2();
}

document.addEventListener("turbolinks:load", function() {
  renderMenu(false);
  bindReloadMenu();
  bindMenuSwitch();
  setTriggerUnlessExist();
  initPageHangUp();
  initLinkDiffEnable();
  initQuickJump();
});
