import React from 'react';
import { Form, Row, Col, Input, Button, Select, DatePicker } from 'antd';
import moment from 'moment'

export default function Search(props) {
  const [form] = Form.useForm();
  const dateTimeFormat = 'YYYY-MM-DD';

  const defaultValues = {
    begin_at: moment(),
    end_at: moment().add(7, 'days'),
    store_id: props.store_list.slice(0, 1).map(s => s[1])
  }

  const onFinish = (fieldsValue) => {
    const begin_at = fieldsValue['begin_at'] !== undefined ? fieldsValue['begin_at'].format('YYYY-MM-DD') : null
    const end_at = fieldsValue['end_at'] !== undefined ? fieldsValue['end_at'].format('YYYY-MM-DD 23:59:59') : null

    const values = {
      ...fieldsValue,
      begin_at: begin_at,
      end_at: end_at,
    }
    props.onClickConfirm(values)
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      style={{
        marginTop: '8px',
        padding: '0% 1%',
      }}
      onFinish={onFinish}
      initialValues={defaultValues}
    >
      <Row gutter={24}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
        >
          <Form.Item name={`store_id`} label="门店">
            <Select
              allowClear
              showSearch
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                props.store_list.map(store => (
                  <Select.Option value={store[1]} key={store[1]}>{store[0]}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
        >
          <Form.Item name={`car_no`} label="车牌号">
            <Input />
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
        >
          <Form.Item name={`begin_at`} label="起始时间">
            <DatePicker style={{width: '100%'}} format={dateTimeFormat} showNow/>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
        >
          <Form.Item name={`end_at`} label="截止时间">
            <DatePicker style={{width: '100%'}} format={dateTimeFormat} showNow/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          style={{
            textAlign: 'left',
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{
              background: '#f36f4a',
              color: '#ffffff',
              borderColor: '#ffffff',
              borderRadius: '10px'
            }}
          >
            搜索
          </Button>
          <Button
            style={{
              margin: '0 8px',
              borderRadius: '10px'
            }}
            onClick={() => {
              form.resetFields();
            }}
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
