import React from 'react';
export default class Panel extends React.Component {
  render() {
    return <div className="panel panel-default">
      <div className="panel-heading">{this.props.heading}</div>
      <div className="panel-body">
        {this.props.children}
      </div>
    </div>;
  }
}
