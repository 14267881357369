/* 021. Highlight Menu
  ------------------------------------------------ */

var _parent_menu_obj = function(menu) {
  return $(menu).closest('.dropdown').prev('a')
}

var highlightMenu = function() {
  "use strict";

  var match_hrefs = [];

  $('.justgo-store-nav .dropdown-menu a').each(function() {
    var pathname = window.location.pathname;
    var menu = $(this);
    var href = $(this).attr('href');

    // not the root path
    if (href.length > 2 && pathname.indexOf(href) == 0) {
      match_hrefs.push(href);
    }
  });

  var find = match_hrefs.sort().pop();
  if (find) {
      var menu =  $('.justgo-store-nav .dropdown-menu a[href="' + find + '"]');
      menu.closest('.dropdown-menu').prev('a').addClass('active_parent_menu');
      menu.addClass('active_menu');
  }

};

/* 03. Handle Sidebar - Mobile View Toggle
    ------------------------------------------------ */
var handleMobileSidebarToggle = function() {
  $('.sidebar').click(function(e) {
    // commit out to use turbolink jump
    e.stopPropagation();
  });

  $('[data-click=sidebar-toggled]').click(function(e) {
    e.stopPropagation();
    var sidebarClass = 'sidebar-toggled';
    var targetContainer = '#page-container';
    if ($(targetContainer).hasClass(sidebarClass)) {
      $(targetContainer).removeClass(sidebarClass);
    } else {
      $(targetContainer).addClass(sidebarClass);
    }
  });
};


/* 04. Handle Sidebar - Minify /展开
    ------------------------------------------------ */
var handleSidebarMinify = function() {
  $('[data-click=sidebar-minify]').click(function(e) {
    e.preventDefault();
    var sidebarClass = 'sidebar-minified';
    var targetContainer = '#page-container';
    if ($(targetContainer).hasClass(sidebarClass)) {
      $(targetContainer).removeClass(sidebarClass);
      $(".nav li .hand b").show();
      $(this).find("i").removeClass("fa-angle-double-right").addClass(
        "fa-angle-double-left");

    } else {
      $(targetContainer).addClass(sidebarClass);
      $(".nav li.active ul").hide();
      $(".nav li.active .hand b").removeClass("caretup").addClass("caret");
      $(".nav li .hand b").hide();
      $(this).find("i").removeClass("fa-angle-double-left").addClass(
        "fa-angle-double-right");
    }
    $(window).trigger('resize');
  });
};


/* 05. Handle Page Load - Fade in
  *     Edit for turbolink by Zhong Kexian
    ------------------------------------------------ */
var handlePageContentView = () => {
  document.addEventListener("turbolinks:load", function() {
    $('#page-loader').addClass('hide')
  });
};


/* 06. Handle Panel - Remove / Reload / 收起 /展开
    ------------------------------------------------ */
var handlePanelAction = function() {
  "use strict";

  // remove
  $('[data-click=panel-remove]').hover(function() {
    $(this).tooltip({
      title: 'Remove',
      placement: 'bottom',
      trigger: 'hover',
      container: 'body'
    });
    $(this).tooltip('show');
  });
  $('[data-click=panel-remove]').click(function(e) {
    e.preventDefault();
    $(this).closest('.panel').remove();
  });

  // collapse
  $('[data-click=panel-collapse]').hover(function() {
    $(this).tooltip({
      title: '收起 / 展开',
      placement: 'bottom',
      trigger: 'hover',
      container: 'body'
    });
    $(this).tooltip('show');
  });
  $('[data-click=panel-collapse]').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).closest('.panel').find('.panel-body').slideToggle();
  });

  // reload
  $('[data-click=panel-reload]').hover(function() {
    $(this).tooltip({
      title: 'Reload',
      placement: 'bottom',
      trigger: 'hover',
      container: 'body'
    });
    $(this).tooltip('show');
  });
  $('[data-click=panel-reload]').click(function(e) {
    e.preventDefault();
    var target = $(this).closest('.panel');
    if (!$(target).hasClass('panel-loading')) {
      var targetBody = $(target).find('.panel-body');
      var spinnerHtml =
        '<div class="panel-loader"><span class="spinner-small"></span></div>';
      $(target).addClass('panel-loading');
      $(targetBody).prepend(spinnerHtml);
      setTimeout(function() {
        $(target).removeClass('panel-loading');
        $(target).find('.panel-loader').remove();
      }, 2000);
    }
  });


  // expand
  $('[data-click=panel-expand]').hover(function() {
    $(this).tooltip({
      title: '展开 / 收起 ',
      placement: 'bottom',
      trigger: 'hover',
      container: 'body'
    });
    $(this).tooltip('show');
  });

  $('[data-click=panel-expand]').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    var target = $(this).closest('.panel');

    if ($('body').hasClass('panel-expand') && $(target).hasClass(
        'panel-expand')) {
      $('body, .panel').removeClass('panel-expand');
      $('.panel').removeAttr('style');
    } else {
      $('body').addClass('panel-expand');
      $(this).closest('.panel').addClass('panel-expand');
    }
    $(window).trigger('resize');

  });
};


/* 07. Handle Panel - Draggable
    ------------------------------------------------ */
var handleDraggablePanel = function() {
  "use strict";
  var target = '[class*=col]';
  var targetHandle = '.panel-heading';
  var connectedTarget = '.row > [class*=col]';

  $(target).sortable({
    handle: targetHandle,
    connectWith: connectedTarget
  });
};


/* 08. Handle Tooltip & Popover Activation
    ------------------------------------------------ */
var handelTooltipPopoverActivation = function() {
  "use strict";
  $('[data-toggle=tooltip]').tooltip();
  $('[data-toggle=popover]').popover();
};


/* 09. Handle Scroll to Top Button Activation
    ------------------------------------------------ */
var handleScrollToTopButton = function() {
  "use strict";
  $(document).scroll(function() {
    var totalScroll = $(document).scrollTop();

    if (totalScroll >= 200) {
      $('[data-click=scroll-top]').addClass('in');
    } else {
      $('[data-click=scroll-top]').removeClass('in');
    }
  });

  $('[data-click=scroll-top]').click(function(e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: $("body").offset().top
    }, 500);
  });
};

/* Application Controller
    ------------------------------------------------ */
var App = function() {
  "use strict";

  return {
    //main function
    init: function() {
      handleMobileSidebarToggle();
      handleSidebarMinify();
      handlePageContentView();
      handlePanelAction();
      handelTooltipPopoverActivation();
      handleScrollToTopButton();
      highlightMenu();
    }
  };
}()

$(function() {
  App.init();
});
