import React from 'react';
export default class TagItem extends React.Component {

  handleDeleteClick = (e) => {
    this.props.deleteItem(this.props.index);
  }

  render () {
    let name = this.props.name;

    return (
      <div className='btn-group' style={{ marginRight: '20px' }}>
        <button type='button' className='btn btn-primary btn-sm' disabled>{name}</button>
        <button type='button' className='btn btn-secondary btn-sm' onClick={this.handleDeleteClick}>
          <span>X</span>
        </button>
      </div>
    );
  }
}
