(function() {
  window.city_store_car_category = function(opts) {
    var helper;
    if (opts == null) {
      opts = {};
    }
    helper = {
      city_string: 'select.js_city_selector',
      store_string: 'select.js_store_selector',
      car_category_string: 'select.js_car_category_selector',
      district_string: 'select.js_district_selector',
      ota_string: 'select.js_ota_selector',
      additional_good_string: 'select.js_additional_good_selector',
      insurance_added_string: "select.js_insurance_added_selector",
      near_city: function(selector) {
        return this._near_item(selector, this.city_string);
      },
      near_store: function(selector) {
        return this._near_item(selector, this.store_string);
      },
      near_car_category: function(selector) {
        return this._near_item(selector, this.car_category_string);
      },
      near_district: function(selector) {
        return this._near_item(selector, this.district_string);
      },
      near_ota: function(selector) {
        return this._near_item(selector, this.ota_string);
      },
      near_additional_good: function(selector) {
        return this._near_item(selector, this.additional_good_string);
      },
      near_insurance_added: function(selector) {
        return this._near_item(selector, this.insurance_added_string);
      },
      _near_item: function(selector, type) {
        var near;
        near = $(selector).parent().find(type);
        if (near.length > 0) {
          return near;
        } else {
          return $(type);
        }
      },
      wrap_selector: function(selector) {
        if (selector.attr('multiple')) {
          selector.multiselect({
            selectedList: 2,
            minWidth: '160px'
          });
          selector.multiselect("refresh");
          return selector.multiselectfilter();
        } else {
          return selector.select2();
        }
      },
      load_stores: function(selector, district_id) {
        var exclude_convenient_store, exclude_store_types, need_all, need_convenient_store, reservable_store_only, that, that_near_store;
        that = this;
        that_near_store = that.near_store(selector);
        need_convenient_store = that_near_store.data("need-convenient-store");
        exclude_store_types = that_near_store.data("exclude-store-types");
        reservable_store_only = that_near_store.data("reservable_store_only");
        need_all = that_near_store.data("need_all");
        exclude_convenient_store = that_near_store.data("exclude_convenient_store");
        return $.getJSON('/car_status/load_store_options_query.json', {
          id: that.near_city(selector).val(),
          district_id: district_id,
          need_convenient_store: need_convenient_store,
          exclude_store_types: exclude_store_types,
          reservable_store_only: reservable_store_only,
          need_all: need_all,
          exclude_convenient_store: exclude_convenient_store
        }, function(data) {
          var item, placeholder, s;
          placeholder = that_near_store.data("placeholder");
          if (placeholder !== void 0) {
            data.unshift([placeholder, ""]);
          } else if (opts.district_need_add_head_item === true) {
            data.unshift(["请选择门店", ""]);
          }
          s = that_near_store.html(((function() {
            var _i, _len, _results;
            _results = [];
            for (_i = 0, _len = data.length; _i < _len; _i++) {
              item = data[_i];
              _results.push("<option value='" + item[1] + "'>" + item[0] + "</option>");
            }
            return _results;
          })()).join(''));
          return that.wrap_selector(s).trigger('change');
        });
      }
    };
    helper.wrap_selector($(helper.city_string));
    $('body').on('change', helper.city_string, function() {
      var that;
      that = this;
      if ($(helper.district_string).length !== 0) {
        return $.getJSON('/car_status/load_district_options_query.json', {
          id: helper.near_city(that).val()
        }, function(data) {
          var item, s;
          if (opts.district_need_add_head_item === true) {
            data.unshift(["请选择", ""]);
          }
          s = helper.near_district(that).html(((function() {
            var _i, _len, _results;
            _results = [];
            for (_i = 0, _len = data.length; _i < _len; _i++) {
              item = data[_i];
              _results.push("<option value='" + item[1] + "'>" + item[0] + "</option>");
            }
            return _results;
          })()).join(''));
          return helper.wrap_selector(s).trigger('change');
        });
      } else {
        return helper.load_stores(that, helper.near_district(that).val());
      }
    });
    helper.wrap_selector($(helper.district_string));
    $('body').on('change', helper.district_string, function() {
      return helper.load_stores(this, helper.near_district(this).val());
    });
    helper.wrap_selector($(helper.store_string));
    helper.wrap_selector($(helper.ota_string));
    helper.wrap_selector($(helper.additional_good_string));
    helper.wrap_selector($(helper.insurance_added_string));
    $('body').on('change', helper.store_string, function() {
      var check_idle, get_goods_url, need_all_store, return_at, store_id, take_at, that;
      that = this;
      store_id = helper.near_store(that).val();
      check_idle = helper.near_car_category(that).data("check-idle");
      need_all_store = helper.near_car_category(that).data("need-all-store");
      take_at = helper.near_car_category(that).data("take-car-at");
      return_at = helper.near_car_category(that).data("return-car-at");
      $.getJSON('/car_status/load_car_category_options_query.json', {
        id: store_id,
        city_id: helper.near_city(that).val(),
        check_idle: check_idle,
        take_at: take_at,
        return_at: return_at,
        need_all_store: need_all_store
      }, function(data) {
        var item, s;
        s = helper.near_car_category(that).html(((function() {
          var _i, _len, _results;
          _results = [];
          for (_i = 0, _len = data.length; _i < _len; _i++) {
            item = data[_i];
            _results.push("<option value='" + item[1] + "'>" + item[0] + "</option>");
          }
          return _results;
        })()).join(''));
        return helper.wrap_selector(s);
      });
      if ($(helper.additional_good_string).length > 0) {
        get_goods_url = "/stores/" + store_id + "/load_addition_goods.json";
        $.getJSON(get_goods_url, function(data) {
          var item, placeholder, s;
          placeholder = helper.near_additional_good(that).data("placeholder");
          if (placeholder !== void 0) {
            data.unshift({
              good_id: "",
              good_name: placeholder
            });
          }
          s = helper.near_additional_good(that).html(((function() {
            var _i, _len, _results;
            _results = [];
            for (_i = 0, _len = data.length; _i < _len; _i++) {
              item = data[_i];
              _results.push("<option value='" + item.good_id + "'>" + item.good_name + "</option>");
            }
            return _results;
          })()).join(''));
          return helper.wrap_selector(s);
        });
      }
      if ($(helper.insurance_added_string).length > 0) {
        get_goods_url = "/stores/" + store_id + "/load_insurance_added_goods.json";
        return $.getJSON(get_goods_url, function(data) {
          var item, placeholder, s;
          placeholder = helper.near_insurance_added(that).data("placeholder");
          if (placeholder !== void 0) {
            data.unshift({
              good_id: "",
              price: "",
              good_name: placeholder
            });
          }
          s = helper.near_insurance_added(that).html(((function() {
            var _i, _len, _results;
            _results = [];
            for (_i = 0, _len = data.length; _i < _len; _i++) {
              item = data[_i];
              _results.push("<option data-price='" + item.price + "' value='" + item.good_id + "'>" + item.good_name + "</option>");
            }
            return _results;
          })()).join(''));
          return helper.wrap_selector(s).trigger('change');
        });
      }
    });
    $('body').on('change', helper.insurance_added_string, function() {
      var price;
      price = $(this).find("option:selected").attr("data-price");
      return $(".insurance_added_price").text(price);
    });
    return helper.wrap_selector($(helper.car_category_string));
  };

}).call(this);
