import React from 'react';
import PriceBoxGroup from "components/bill_request/PriceBoxGroup"

export default class RentMoneyBox extends React.Component {

  constructor(props) {
    super(props);

    this.onCheckboxChange = this.onCheckboxChange.bind(this);

    this.state = { checked_dates: [] };
  }

  onCheckboxChange(event) {
    let {
      checked_dates
    } = this.state;

    if (event.target.checked) {
      checked_dates.push(event.target.value);
    } else {
      checked_dates = _.filter(checked_dates, date => date !== event.target.value);
    }

    this.setState({ checked_dates});
    return this.props.update_rent_bill_requests(checked_dates);
  }

  render() {
    return <div className='row'>
      <PriceBoxGroup financeItems={this.props.financeItems}
        data={this.props.rentMoney} onCheckboxChange={this.onCheckboxChange}
        checked_dates={this.state.checked_dates}
        columnClass='col-md-3'
        />
    </div>;
  }
}
