/**
* 用于显示modal
* 例子 data-modal-data="{'modal_class':'modal-width-60','url':'/cars/new', 'title': '添加车辆', 'id': 'add_car'}
* 其中id, title, 及url为必要参数
* modal_class目前支持modal-width-50到modal-width-100, 从50%到100%
* need_button表示是否需要button
* backdrop 为 static(String) 时，点击模态对话框的外部区域不会将其关闭。
* keyboard 为 false(Boolean) 时，按下 Esc 键不会关闭 Modal。
*/
export function showModal(thisobj)
{
  var option = $(thisobj).data('modal-data');
  if (option)
  {
    var id = option.id;
    var title = option.title;
    var url = option.url;
    var modal_class = option.modal_class;
    var need_button = option.need_button;
    var button_html = ''

    if(need_button)
    {
      button_html = '<button class="btn btn-primary" id="my_' + id +'_button" name="button" type="button">确认</button>' +
                    '<button class="btn btn-secondary" data-dismiss="modal" name="button" type="button">关闭</button>';
    }
    if(!document.getElementById(id)) {
      var info = '<div class="modal fade" id="'+ id + '_modal" role="dialog" >' +
                 '<div class="modal-dialog ' + modal_class +'">' +
                 '<div class="modal-content">' +
                 '<div class="modal-header">' +
                 '<h4 class="modal-title" id="myModalLabel">'+ title +'</h4>' +
                 '<button type="button" class="close" data-dismiss="modal">' +
                 '<span >&times;</span>' +
                 '<span class="sr-only">Close</span></button>' +
                 '</div>' +
                 '<div class="modal-body" id="'+ id + '">' +
                 '</div>' +
                 '<div class="modal-footer">' +
                 button_html +
                 '</div>' +
                 '</div>' +
                 '</div>' +
                 '</div>'
      $('body').append(info);
    }
    render_html_content(id, url);
    $("#" + id +'_modal').modal();
    $("#my_" + id +"_button").unbind();
  }
}

// 使用JSONP跨越子域名
export function render_html_content(id, url) {
  var $modal = $("#" + id);
  $modal.html('');
  $.ajax({
    url : url,
    dataType : "text",
    success : function(html_content) {
      $modal.html(html_content);
    }
  });
}
