var BusinessDistrictData = {
  init: function() {
    return $(".js_business_manage").on('click', function() {
      return BusinessDistrictData.do_action('#business_district_manage', '/business_district_data/business_district_manage');
    });
  },
  do_action: function(action, url) {
    var params;
    params = {
      business_district_ids: BusinessDistrictData.get_selected_business_district_ids(),
      date: $(action + ' #date').val()
    };
    if (params.business_district_ids.length <= 0) {
      alert('请选中商圈');
      return false;
    }
    return $.post(url, params).done(function(data) {
      if (data.status) {
        alert('操作成功');
        return window.location.reload();
      } else {
        return alert("操作失败: " + data.message);
      }
    });
  },
  get_selected_business_district_ids: function() {
    return $('[name="business_district_ids[]"]:checked').map(function() {
      return this.value;
    }).get();
  }
};

document.addEventListener("turbolinks:load", function() {
  if ($('body').attr('data-controller') === 'business_district_data') {
    return BusinessDistrictData.init();
  }
});
