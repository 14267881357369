import React from 'react';
import Panel from "components/bill_request/Panel"
import RentMoneyBox from "components/bill_request/RentMoneyBox"
import OtherConsumeSelector from "components/bill_request/OtherConsumeSelector"
import BillRequstItem from "components/bill_request/BillRequstItem"
import OffsetItemSelector from "components/bill_request/OffsetItemSelector"
import OffsetReasonSelector from "components/bill_request/OffsetReasonSelector"
import FormGroup from "components/bill_request/FormGroup"
export default class AddOffsetForm extends React.Component {

  constructor(props) {
    super(props);

    this.update_rent_bill_requests = this.update_rent_bill_requests.bind(this);
    this.update_bill_requests = this.update_bill_requests.bind(this);
    this.add_other_bill_requests = this.add_other_bill_requests.bind(this);
    this.removeItemWithButton = this.removeItemWithButton.bind(this);
    this.update_offset_reason = this.update_offset_reason.bind(this);
    this.update_offset_reason_rule = this.update_offset_reason_rule.bind(this);
    this.submitForm = this.submitForm.bind(this);

    const data =  JSON.parse(this.props.data);
    console.log('Base data', data);

    let code_max_money = {};
    const reasons = [];
    const offset_reason_rule = [];
    const rule_init = [];
    const note_init = [];
    _.each(data.other_consume_money, (info, code) => code_max_money[code] = info.money);

    _.each(data.rent_money, (code_money, date) => code_max_money = _.merge(code_max_money, code_money, function(a, b) {
      if (_.isNumber(a)) { return a + b; }
    }));
    _.map(data.offset_reasons, function(reason) {
      if (reason.fee_category_id === data.offset_categories[0].id) { return reasons.push(reason); }
    });

    _.map(reasons, function(reason, index) {
      if (index === 0) { rule_init.push(reason.rule); }
      if (index === 0) { return note_init.push(reason.upload_detail_note); }
    });
    this.state = {
      data: JSON.parse(this.props.data),
      bill_requests: {},
      code_max_money,
      categories: data.offset_categories,
      offset_reason: reasons,
      offset_reason_rule: rule_init,
      offset_reason_note: note_init
    };
  }

  update_rent_bill_requests(checked_dates) {
    const _self = this;
    let bill_requests = {};
    _.forEach(checked_dates, date => {
      return bill_requests = _.merge(bill_requests, _self.state.data.rent_money[date], function(a, b) {
        if (_.isNumber(a)) { return a + b; }
      });
    });

    return this.setState({bill_requests, checked_dates});
  }

  update_bill_requests(bill_request) {
    const code = _.keys(bill_request)[0];
    if (!_.isNumber(bill_request[code])) { bill_request[code] = 0; }
    const money = bill_request[code];

    if ((money >= 0) && (money <= this.state.code_max_money[code])) {
      return this.add_other_bill_requests(bill_request);
    } else {
      return console.log('money is too large or illegal');
    }
  }

  add_other_bill_requests(bill_request) {
    const bill_requests = _.merge(this.state.bill_requests, bill_request);
    return this.setState({bill_requests});
  }

  removeItemWithButton(code_to_remove) {
    const bill_requests = {};
    _.each(this.state.bill_requests, function(money, code) {
      if (code !== code_to_remove) { return bill_requests[code] = money; }
    });

    return this.setState({bill_requests});
  }

  update_offset_reason(fee_category_id) {
    const reasons = [];
    const data_reasons = this.state.data.offset_reasons;
    _.map(data_reasons, function(reason) {
      if (reason.fee_category_id === fee_category_id) { return reasons.push(reason); }
    });

    return this.setState({offset_reason: reasons, offset_reason_rule: [reasons[0].rule], offset_reason_note: [reasons[0].upload_detail_note]});
  }

  update_offset_reason_rule(reason_id) {
    const data_reasons = this.state.data.offset_reasons;
    const rule = [];
    const note = [];
    let single_upload = null;
    _.map(data_reasons, function(reason) {
      if (reason.id === reason_id) { rule.push(reason.rule); }
      if (reason.id === reason_id) { note.push(reason.upload_detail_note); }
      if (reason.id === reason_id) { return single_upload = reason.document_identification_check; }
    });
    return this.setState({offset_reason_rule: rule, offset_reason_note: note, document_identification_check: single_upload});
  }

  submitForm(event) {
    const _self = this;
    const btn = $(event.target).button('loading');
    const {
      code_max_money
    } = this.state;
    const {
      checked_dates
    } = this.state;
    const bill_requests = _.map(this.state.bill_requests, (money, code) => ({
      money,
      code,
      max_offset_money: code_max_money[code]
    }));

    const order_id = this.state.data.order.id;

    const sum = _.sum(this.state.bill_requests);
    const error =
      sum <= 0 ?
        '请添加需要冲减的内容' : undefined;

    if (error) {
      alert(error);
      btn.button('reset');
      return;
    }

    const category = $("#offsetCategorySelector  option:selected").text();
    const reason = $("#offsetReasonSelector  option:selected").text();
    const reason_id = $("#offsetReasonSelector  option:selected").val();
    const comment = $("#comment").val();
    const image_type = $('#image_type_select').val();

    const formData = new FormData($("#offset_form")[0]);
    $.each(bill_requests, function(index, bill_request) {
      formData.append('bill_requests[]code', bill_request.code);
      formData.append('bill_requests[]money', bill_request.money);
      return formData.append('bill_requests[]max_offset_money', bill_request.max_offset_money);
    });

    formData.append('order_id', order_id);
    formData.append('category', category);
    formData.append('reason', reason);
    formData.append('comment', comment);
    formData.append('reason_id', reason_id);
    formData.append('checked_dates', checked_dates);
    formData.append('image_type', image_type);

    return $.ajax({
      url: '/bill_requests/do_add_offset',
      type: 'POST',
      data: formData,
      cache: false,
      processData: false,
      contentType: false,
      success: data => {
        if (data['status'] === 'ok') {
          alert('提交成功，审批通过后入账。现在跳转回订单详情\n'+ data['msg']);
          return window.location.href = `/orders/${_self.state.data.order.id}`;
        } else {
          alert(`提交失败：${data['error']}`);
          return btn.button('reset');
        }
      }
    });
  }

  render() {
    const file_style = {
      width: 447,
      display: 'inline-block',
      marginRight: 20
    };
    const single_upload = this.state.document_identification_check;
    const sum = _.sum(this.state.bill_requests);
    return <Panel heading="冲减申请">
      <form className='form-horizontal' id="offset_form" encType="multipart/form-data">
        <b>订单号 {this.state.data.order.order_no} </b>

        <fieldset>
          <legend>车辆租金</legend>
          <RentMoneyBox rentMoney={this.state.data.rent_money} financeItems={this.state.data.finance_items}
            update_rent_bill_requests={this.update_rent_bill_requests} />
        </fieldset>


        <fieldset>
          <legend>其他消费</legend>
          <OtherConsumeSelector
            otherConsumeMoney={this.state.data.other_consume_money}
            financeItems={this.state.data.finance_items}
            add_other_bill_requests={this.add_other_bill_requests} />
        </fieldset>

        <fieldset>
          <legend>准备冲减的内容</legend>
            <div className='row'>
              <div className="row col-md-6">
                <BillRequstItem
                  data={this.state.bill_requests}
                  financeItems={this.state.data.finance_items}
                  removeItemWithButton={this.removeItemWithButton}
                  update_bill_requests={this.update_bill_requests}
                  code_max_money={this.state.code_max_money}
                />
                <OffsetItemSelector
                  update_offset_reason={this.update_offset_reason}
                  offsetItems={this.state.categories} />

                <OffsetReasonSelector
                  offsetReasonItems={this.state.offset_reason}
                  offsetReasonRule={this.state.offset_reason_rule}
                  update_offset_reason_rule={this.update_offset_reason_rule}/>

                <FormGroup id='image_type' label='证件类型'>
                  <select id="image_type_select" className='form-control' style={file_style}
                    disabled={single_upload ? '' : 'disabled'}>
                    <option value="id_card_back">身份证证件反面</option>
                    <option value="driving_license">驾驶证正本</option>
                  </select>
                </FormGroup>

                <FormGroup id='supply_reason' label='附件上传'>
                  <input id='file' name='pics[]' ref="supply_reason" type="file"
                  multiple={single_upload ? '' : 'multiple'}
                  className="form-control" accept=".jpg,.gif,.png,.jpeg" style={file_style}/>
                  <label>减免原因附件补充</label>
                  <label style={{color:'red'}}>{this.state.offset_reason_note}</label>
                </FormGroup>

                <FormGroup id='comment' label='备注'>
                  <textarea id='comment' name='comment' className="form-control" style={file_style}></textarea>
                </FormGroup>
              </div>
              <div className="row col-md-6">
                <div>
                  <div className="panel panel-success">
                    <div className="panel-body">
                      <div id='match_condition'>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </fieldset>

        <div>
          <span className="text-warning" style={{fontSize: 15}}>
            待收 {this.state.data.due_payment} - 冲减总额 {sum} = 冲减入账后的待收 {this.state.data.due_payment - sum} </span>
          <button type="button" className="pull-right btn btn-primary" data-loading-text="提交中..."
            onClick={this.submitForm} style={{marginLeft: '20px'}}>提交</button>
        </div>
      </form>
    </Panel>;
  }
}
