import moment from 'moment'

document.addEventListener("turbolinks:load", function() {

  var lease = $(".datepicker-car-status", ".search-car-status");
  lease.datetimepicker({
    stepping: 30,
    // inline: true,
    // sideBySide: true
  });

  var q_take_at = $("#q_take_at");
  var q_return_at = $("#q_return_at");
  q_take_at.on("dp.change", function(e) {
    q_return_at.data("DateTimePicker").options({minDate: e.date});
    var take_hour = moment(q_take_at.val()).hours();
    var return_at = moment(q_return_at.val()).hours(take_hour).format("YYYY-MM-DD HH:mm");
    q_return_at.val(return_at);
    get_return_stores();
  });

  q_return_at.on("dp.change", function(e) {
    q_take_at.data("DateTimePicker").options({maxDate: e.date});
  });

  window.city_store_car_category({ district_need_add_head_item: true });
  var takeCarStore = $('#q_store_id');
  var returnCarStore = $('#q_return_car_store_id');
  show_tips_for_store();


  // 通过取车门店，获取对应的还车门店
  function get_return_stores(){
    var data = $('#my_order_form').serialize()
    var url = "/car_status/return_car_stores?" + data
    var originalReturnCarStoreId = returnCarStore.val();
    $.ajax({
      method: "get",
      url: url,
      success: function(data){
        var return_car_store_options = data.return_car_store_options
        var return_car_store_id = data.return_car_store_id
        var option_list = ['<option value="">请选择还车门店</option>']

        // 填充还车门店下拉框
        returnCarStore.empty()
        $.each(return_car_store_options, function( index, value ) {
          option_list.push('<option value="' + value[1] + '">' + value[0] + '</option>')
        });
        returnCarStore.append(option_list.join(''));

        // 选中
        returnCarStore.val(return_car_store_id);
        if (originalReturnCarStoreId !== return_car_store_id) {
          returnCarStore.trigger('change');
        } else if (takeCarStore.val() !== return_car_store_id) {
          show_tips_for_store();
        }
      }
    });
  }

  // 门店准备中提示
  function show_tips_for_store(){
    if ($('#take_store').length === 0 || $('#return_car_store') === 0) {
      return
    }
    var take_store_id = takeCarStore.val();
    var return_car_store_id = returnCarStore.val();
    if (return_car_store_id === null || return_car_store_id === "") {
      return_car_store_id = take_store_id;
    }
    var url = "/stores/show_tips_by_store";

    $.ajax({
      method: "get",
      url: url,
      data: {take_store_id: take_store_id, return_car_store_id: return_car_store_id},
      success: function(data){
        if(data.take_store_is_preparing){
          $('#take_store').text('门店准备中,下单请谨慎');
        }else{
          $('#take_store').text('');
        }
        if(data.return_car_store_is_preparing){
          $('#return_car_store').text('门店准备中,下单请谨慎');
        }else{
          $('#return_car_store').text('');
        }
      }
    });
  }

  takeCarStore.on('change', function(){
    get_return_stores();
  });

  returnCarStore.on('change', function(){
    show_tips_for_store();
  })
});
