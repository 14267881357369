var Tree = (function(){

var folding = "+"
var unfold = "- "

function hide(node){
  node.folded = node.folded + 1
  node.element.style.display = 'none'
  node.loopChildren(hide)
}

function show(node){
  node.folded = node.folded - 1
  if (node.folded === 0) node.element.style.display = ''
  node.loopChildren(show)
}

function TreeNode (id, data) {
  this.id = id
  this.data = data
  this.element = null
  this.input = null
  this.parent = null
  this.depth = 0
  this.children = []
  this.folded = 0
}

function Tree (params) {
  var _self = this

  if (params.node) {
    this._root = params.node
    this.isSub = true
  } else {
    var node = new TreeNode(params.id, params.data)
    this._root = node
    this._table = document.getElementById(params.tableId)

    var body = this._table.tBodies[0]
    body.innerHTML = ''
    body.appendChild(node.createElement())

    // 绑定查询输入框
    if (params.searchInputId) {
      var searchInput = document.getElementById(params.searchInputId)
      searchInput.onkeypress = function (e) {
        if (e.keyCode === 13) {
          _self.search(searchInput.value, _self.traverseDF)
        }
      }
    }
  }
}

// 创建dom元素
TreeNode.prototype.createElement = function () {
  var _self = this
  var tr = document.createElement('tr')
  this.element = tr

  var td = document.createElement('td')
  for (var i = 0; i < this.depth; i++) {
    var span = document.createElement('span')
    span.className = 'treegrid-indent'
    td.appendChild(span)
  }
  td.insertAdjacentHTML('beforeend', "<span class='treegrid-fold'>&nbsp;&nbsp;</span>&nbsp;&nbsp;");

  var input = document.createElement('input')
  this.input = input
  input.type = 'checkbox'
  input.className = 'tree-grid-select'
  input.id = this.id
  // 全选或清空子选项
  input.onchange = function (e) {
    _self.check(input.checked)
  }

  td.appendChild(input)
  td.insertAdjacentHTML('beforeend', "&nbsp;&nbsp;<span>" + this.data.name + "</span>");

  tr.appendChild(td)

  return tr
}

// 勾选或清空其父选项与子选项
TreeNode.prototype.check = function (checked) {
  // 取消勾选时不取消父选项
  this.checkParents(true)
  // 勾选所有子节点, 包括自己
  this.traverse(function (node) {
    node.input.checked = checked
  }, 'bf')
}

// 勾选父选项
TreeNode.prototype.checkParents = function (checked) {
  var parent = this.parent
  if (parent) {
    parent.input.checked = checked
    parent.checkParents(checked)
  }
}

// 树节点遍历
TreeNode.prototype.traverse = function(callback, traversal) {
  var tree = new Tree({ node: this })
  traversal === 'bf' ? tree.traverseBF(callback) : tree.traverseDF(callback)
}

// 深度优先搜索
Tree.prototype.traverseDF = function (callback) {
  (function recurse(currentNode) {
    callback(currentNode)
    for (var i = 0; i < currentNode.children.length; i++) {
      recurse(currentNode.children[i])
    }
  })(this._root)
}

// 宽度优先搜索
Tree.prototype.traverseBF = function (callback) {
  var queue = []

  currentTree = this._root

  while(currentTree){
    callback(currentTree)
    for (var i = 0; i < currentTree.children.length; i++) {
      queue.push(currentTree.children[i])
    }
    currentTree = queue.shift()
  }
}

// 查找节点
Tree.prototype.contains = function (callback, traversal) {
  traversal.call(this, callback)
}

// 查找dom
Tree.prototype.search = function (name, traversal) {
  var flag = name.length > 0
  var results = []
  traversal.call(this, function (node) {
    // name为空时显示整棵树
    node.toggleDisplay(!flag)
    if (flag && node.data.name.indexOf(name) >= 0) {
      node.element.style.color = 'red'
      results.push(node)
    } else {
      node.element.style.color = ''
    }
  })
  for (var i = 0; i < results.length; i++) {
    results[i].show()
  }
}

// 返回所有已选节点
Tree.prototype.selectedNodes = function () {
  var results = []
  this.traverseDF(function (node) {
    if (node.input.checked) {
      results.push(node)
    }
  })
  return results
}

// toggle显示dom
TreeNode.prototype.toggleDisplay = function (flag) {
  this.element.style.visibility =  flag ? 'visible' : 'hidden'
  this.element.style.position = flag ? '' : 'absolute'
}

// 显示目标节点的所有父节点和子节点
TreeNode.prototype.show = function () {
  this.showParents()
  // 显示所有子节点, 包括自己
  this.traverse(function (node) {
    node.toggleDisplay(true)
  }, 'bf')
}

// 显示目标节点的所有父节点
TreeNode.prototype.showParents = function () {
  var parent = this.parent
  if (parent) {
    parent.toggleDisplay(true)
    parent.showParents()
  }
}

TreeNode.prototype.loopChildren = function(fn){
  for (var i = 0; i < this.children.length; i++) {
    fn(this.children[i]);
  }
}

TreeNode.prototype.prependFoldButton = function() {
  var button = this.element.querySelector('.treegrid-fold')
  if (button.innerText === unfold ) return
  button.innerText = unfold

  var that = this
  button.addEventListener('click', function(e){
    e.preventDefault()
    that.switchDisplay()
  });
}

TreeNode.prototype.switchDisplay = function(){
  var button = this.element.querySelector('.treegrid-fold')
  if (button.innerText === folding) {
    button.innerText = unfold;
    this.loopChildren(show)
  } else {
    button.innerText = folding
    this.loopChildren(hide)
  }
}

// 跳出回调
var jumpOutError = new Error('jump out')

// 插入节点
Tree.prototype.add = function (id, data, parentId, traversal) {
  if (!parentId) { return }
  var child = new TreeNode(id, data),
      parent = null,
      callback = function (node) {
        if (node.id === parentId) {
          parent = node
          throw jumpOutError
        }
      }

  try {
    this.contains(callback, traversal)
  } catch (e) {
    if (e !== jumpOutError) { throw e }
  }

  if (parent) {
    var count = parent.children.length
    var lastElement = parent.latestNode().element

    child.parent = parent
    child.depth = parent.depth + 1
    parent.children.push(child)

    parent.prependFoldButton();

    var element = child.createElement()
    insertAfter(element, lastElement)
  } else {
    throw new Error('Cannot add node to a non-existent parent.')
  }
}

// 插入到目标元素的后面
function insertAfter(element, target){
  var parent = target.parentNode
  if (parent.lastChild === target) {
    parent.appendChild(element)
  } else {
    parent.insertBefore(element, target.nextSibling)
  }
}

TreeNode.prototype.latestNode = function () {
  var count = this.children.length
  return count < 1 ? this : this.children[count - 1].latestNode()
}

// 批量插入节点
Tree.prototype.batchAdd = function (payload, traversal) {
  this._table.style.display = 'none'
  for (var i = 0; i < payload.length; i++) {
    this.add(payload[i].id, payload[i], payload[i].parent_id, traversal)
  }
  this._table.style.display = ''
}

// 批量勾选节点
Tree.prototype.selectNodes = function (ids) {
  var stack = ids.slice(0)
  var currentId = stack.pop()
  while (currentId) {
    this.selectNode(currentId)
    currentId = stack.pop()
  }
}

// 勾选节点
Tree.prototype.selectNode = function (id) {
  this.contains(function (node) {
    if (node.id === id) {
      node.input.checked = true
    }
  }, this.traverseDF)
}

// 删除节点和其所有子节点
Tree.prototype.remove = function (id, parentId, traversal) {
  var tree = this,
      parent = null,
      childToRemove = null,
      index

  var callback = function (node) {
    if (node.id === parentId) {
      parent = node
    }
  }

  this.contains(callback, traversal)

  if (parent) {
    index = findIndex(parent.children, id)

    if (index === undefined) {
      throw new Error('Node to remove does not exist.')
    } else {
      childToRemove = parent.children.splice(index, 1)
    }
  } else {
    throw new Error('Parent does not exist.')
  }

  return childToRemove
}

// 查找子节点下标
function findIndex (children, id) {
  var index

  for (var i = 0; i < children.length; i++) {
    if (arr[i].id === id) {
      index = i
    }
  }

  return index
}

return Tree
})();

window.Tree = Tree;
