function initDefaultSelect2() {

  function buttons_literal(select2_id){
    return '<a class="select2--select-all" data-select-all="true" data-select2-id="' + select2_id + '">全选</a>' +
      '&nbsp;&nbsp;<a class="select2--select-all" data-select-all="false" data-select2-id="' + select2_id + '">清空</a>'
  }

  function create_select_all_buttons($select2){
    var select2_id = $select2.attr('id')
    var literal = buttons_literal(select2_id)
    $select2.next().find('.selection').after(literal)
  }

  $(".select2").each(function() {
    var that = $(this)
    initAsSelect2(that);

    if(that.hasClass('select2-all-clear')){
      create_select_all_buttons(that)
    }
  });

  $('.select2--select-all').on('click', function(e){
    var $target = $(e.target)
    var id = $target.data('select2-id')
    var selected = $target.data('select-all')
    $('#' + id).find('option').prop('selected', selected).trigger('change')
  })

};

function initAsSelect2($jq) {
  var options = arguments[1] || {};
  if ($jq.data('select2Theme') !== null) {
    options.theme = "bootstrap";
  }
  // NOTE 当页面设置了缩放时控件长度可能不太对劲，这时需要指定width为auto才能正常显示
  if ($jq.data('width') !== undefined) {
    options.width = $jq.data('width');
  } else {
    var ratio = window.devicePixelRatio || 1
    if (ratio !== 1 ) {
      options.width = 'auto'
    }
  }

  var parant = $jq.closest('.modal')
  if (parant.length) {
    options.dropdownParent = parant
  }

  if ($jq.data('placeholder') !== null) {
    options.placeholder = {id: -1, text: $jq.data('placeholder')}
  }

  $jq.select2(options)
}

function initRemoteSelect2(code, url){
  $('.select2-' + code).select2({
    theme: 'bootstrap',
    language: 'zh-CN',
    minimumInputLength: 1,
    ajax: {
      url: url,
      dataType: 'json',
      cache: true,
      delay: 250,
      data: function (params) {
        return {
          term: params.term, // 输入框的值
          // have_chose_ids: have_chose, //已经选择的不再查询
          source: 'select2',
          _type: 'query_append',
          page: params.page //第几页返回查询
        };
      },
      processResults: function (data, params) {//结果处理
        params.page = params.page || 1;
        return {
          results: data.items,
          pagination: {
            more: (params.page * 30) < data.total_count //每页30条数据
          }
        }
      }
    }
  })
}

document.addEventListener("turbolinks:load", function() {
  // initDefaultSelect2()
  $(".select2").select2()
  initRemoteSelect2('user', '/users/load');
  initRemoteSelect2('position', '/positions/load');
  initRemoteSelect2('department', '/departments/load');
  initRemoteSelect2('salesman', '/sales_groups/load');
  initRemoteSelect2('car-category', '/car_categories/load_all')
  initRemoteSelect2('store', '/stores/load_all')
})

document.addEventListener("turbolinks:before-cache", () => {
  $('select.select2').select2('destroy')
  $('select.multiselect').multiselect('destroy')
})
