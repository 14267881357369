/* Simplified Chinese initialisation for the jQuery UI multiselect plugin. */
/* Written by Ben (ben@zfben.com). */

(function ( $ ) {

  $.extend($.ech.multiselectfilter.prototype.options, {
    label: '过滤:',
    placeholder: '输入关键字过滤'
  });

  $.extend($.ech.multiselect.prototype.options, {
    checkAllText: '全选',
    uncheckAllText: '清空',
    noneSelectedText: '请选择',
    selectedText: '# 已选择'
  });

})( jQuery );
