import "justgo/constants"

import "select2"

import "jquery-treegrid/js/jquery.treegrid.min"
import "jquery-treegrid/js/jquery.treegrid.bootstrap3"
import "jquery-treegrid/css/jquery.treegrid.css"

import "moment/locale/zh-cn"
import "jsoneditor/dist/jsoneditor.css"

import "channels"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import 'antd/dist/antd.css'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "justgo/select2"

import "jquery_multiselect/jquery.multiselect"
import "jquery_multiselect/jquery.multiselect.filter"
import "jquery_multiselect/jquery.multiselect.zh-cn"

import "blueimp-file-upload"

import "./setup"
import "vendor/common"
import "vendor/justgo.choose.modal"
import "vendor/nested_form"
import "vendor/jquery.cookie"
import "vendor/lodash"
import "vendor/ckeditor"

import "vendor/bootstrap-treeview"
import "vendor/bootstrap-treeview.css"

import "vendor/treegrid"

import "vendor/autocomplete-rails"

import "vendor/bootstrap-datetimepicker.min.css"
import "vendor/bootstrap-datetimepicker.min.js"

require("justgo/init")
require("justgo/menu")
require("justgo/cascading_selector")
require("justgo/json_editor")
require("justgo/business_district_data")
require("justgo/orders")
require("justgo/renter_details")
require("justgo/pays")
require("justgo/take_car")
require("justgo/franchisee_membership_offsets")

require("justgo/real_time_status")
require("justgo/disabled_car_statuses")
require("justgo/fixed_datetimepicker")
