import React from 'react';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';

export function ppHOC(WrappedComponent) {
  return function (props) {
    return (
      <ConfigProvider locale={zhCN}>
        <WrappedComponent {...props}/>
      </ConfigProvider>
    )
  }
}
