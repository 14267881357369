import React from 'react';
import FormGroup from "components/bill_request/FormGroup"

export default class OffsetReasonSelector extends React.Component {
  constructor(...args) {
    super(...args);

    this.handleRuleChange = this.handleRuleChange.bind(this);
  }

  handleRuleChange(event) {
    const index = event.target.value;
    return this.props.update_offset_reason_rule(index);
  }

  render() {
    const group = _.map(this.props.offsetReasonItems, reason_array => {
      return <option key={reason_array.id} value={reason_array.id} >{reason_array.name}</option>;
    });

    const rule = this.props.offsetReasonRule;

    const select_style = {
      width: 447,
      display: 'inline-block',
      marginRight: 20
    };
    return <div>
      <FormGroup id='offsetReasonSelector' label='减免原因'>
        <select id='offsetReasonSelector' className='form-control' style={select_style}
        ref='offsetReasonSelector' onChange={this.handleRuleChange}> {group} </select>
      </FormGroup>

      <FormGroup id='rule' label='规则说明'>
        <textarea className="form-control" style={select_style} disabled='disabled' value={rule}></textarea>
      </FormGroup>

    </div>;
  }
}
