(function($, global) {
  var JustgoChoose = {};
  var save_row_data_id = '_justgo_model_row_data';
  var bound_check_all = {}

  function init_modal(options) {
    if (!options.id) {
      return;
    }
    var obj = $("#" + options.id);
    options.name = options.name || obj.attr("name") || options.id;
    options.type = options.type || "table";
    options.title = options.title || "Choose";
    options.http_verb = options.http_verb || "POST";
    options.fields = options.fields || ["name"];
    options.modal_class = options.modal_class || "modal-width-50";
    options.paginate = options.paginate == undefined ? false : options.paginate;
    options.is_radio = options.is_radio == undefined ? true : options.is_radio;
    options.check_all = options.check_all == undefined ? false : options.check_all;
    options.has_choose_button = options.has_choose_button == undefined ?
      true : options.has_choose_button

    var modal = create_modal(options);

    $(obj).after(modal);

    init_obj(options);
    preselect(options);

    var save_button_id = options.id + "_save_select";
    $("#" + save_button_id).on("click", function(event) {
      on_save(options);
    });
  }
  JustgoChoose.init = init_modal;

  function init_obj(options) {
    var modal_id = options.id + "_modal";
    var modal = $("#" + modal_id);
    var obj = $("#" + options.id);
    $(obj).attr("readonly", true).css("cursor", "pointer");
    var input_group_style = options.input_group_style ? ("style='" + options.input_group_style  + "'") : '';
    var input_group = $("<div class='input-group' " + input_group_style  + "></div>");
    var choose_a_text = options['choose_a_text'] || '选择';
    var choose_a_class = options['choose_a_class'] || 'input-group-addon btn btn-secondary btn-info';
    var choose_a = $("<a style='color: #FFFFFF' class='" + choose_a_class + "'>" + choose_a_text + "</a>");

    choose_a.on("click", function() {
      on_show(options);
      modal.modal();
    });

    $(obj).on("click", function() {
      choose_a.trigger("click");
    });

    $(obj).parent().append(input_group.append(obj));

    var hidden_div_id = options.id + "_hidden_div";
    var hidden_div = $("<div id='" + hidden_div_id + "'></div>");
    var clear_a_class = options['clear_a_class'] || 'input-group-addon btn btn-secondary btn-danger';
    var clear_a = $("<a style='color: #FFFFFF' class='" + clear_a_class + "'>清空</a>");
    clear_a.on("click", function() {
      clear_value(options)
    });

    if (options.is_radio) {
      var hidden_input = $("<input type='hidden' name='" + options.name +
        "' value='" + $(obj).val() + "' node_name='" + options.text +
        "'/>");
      hidden_div.html(hidden_input);
    } else {
      if (options.values && options.text) {
        var value_array = options.values.split(",");
        var text_array = options.text.split(",");
        var hidden_content_array = [];
        for (var i = 0; i < value_array.length; i++) {
          hidden_content_array[i] = "<input type='hidden' name='" + options
            .name + "' value='" + value_array[i] + "' node_name='" +
            text_array[i] + "'/>";
        }
        hidden_div.html(hidden_content_array.join("\n"));
      }
    }

    if ($('#' + save_row_data_id).length == 0) {
      var save_row_data_div = $("<div class='hidden' id='"+save_row_data_id+"'></div>");
      $('body').append(save_row_data_div)
    }

    $(obj).after(hidden_div);
    $(obj).val(options.text);
    $(obj).removeAttr("name");
    $(obj).after(choose_a).after(clear_a);
    if (!options.has_choose_button) {
      choose_a.hide();
      clear_a.hide();
    }
  }

  function on_show(options) {
    var hidden_select_item_div_id = options.id + "_hidden_select_item_div";
    $("#" + hidden_select_item_div_id).html("");
    var hidden_div_id = options.id + "_hidden_div";
    $("#" + hidden_div_id).find(":hidden").each(function(
      hidden_index, hidden_obj) {
      var hidden_selected_id = options.id + $(hidden_obj).val();
      $("#" + hidden_select_item_div_id).append(
        "<input type='hidden' id='" + hidden_selected_id +
        "' value='" + $(hidden_obj).val() +
        "' node_name='" + $(hidden_obj).attr("node_name") + "' />");
    });
    var search_form_id = options.id + "_modal_search_form";
    $("#" + search_form_id).find("input").val("");

    if (options.before_show) {
      options.before_show();
    }

    reload(options);

    if (options.on_show) {
      options.on_show();
    }
  }

  function on_save(options) {
    var modal_id = options.id + "_modal";
    var hidden_div_id = options.id + "_hidden_div";
    var hidden_select_item_div_id = options.id + "_hidden_select_item_div";
    var select_obj = $("#" + hidden_select_item_div_id).find(":hidden");
    var obj = $("#" + options.id);
    if (options.is_radio) {
      $(obj).val($(select_obj[0]).attr("node_name"));
      $("#" + hidden_div_id).html("<input type='hidden' name='" +
        options.name + "' value='" + $(select_obj[0]).val() +
        "' node_name='" + $(select_obj[0]).attr("node_name") + "' />");
    } else {
      var text_array = [];
      var hidden_content_array = [];
      if (select_obj.length > 0) {
        select_obj.each(function(index, _obj) {
          hidden_content_array[index] = "<input type='hidden' name='" +
            options.name + "' value='" + _obj.value + "' node_name='" +
            $(_obj).attr("node_name") + "' />";
          text_array[index] = $(_obj).attr("node_name");
        });
      }
      $(obj).val(text_array.join(","));
      $("#" + hidden_div_id).html(hidden_content_array.join("\n"));
    }
    var hidden_text_name = options.name.replace("[]", "") + "_text";
    hidden_text_obj = $(":hidden[name='" + hidden_text_name + "']");
    if (hidden_text_obj.length > 0) {
      hidden_text_obj.remove();
    }
    obj.after("<input type='hidden' name='" + hidden_text_name +
      "' value='" + obj.val() + "' />");
    if (options.on_save) {
      var value_div = $("#" + hidden_div_id).find("input")
      options.on_save(value_div);
    }
  }

  function create_modal(options) {
    var modal = create_modal_fade(options);
    var modal_dialog = create_modal_dialog(options);
    var modal_content = create_modal_content();
    modal_content.append(create_modal_header(options));
    modal_content.append(create_modal_body(options));
    modal_content.append(create_modal_footer(options));
    modal_dialog.append(modal_content);
    modal.append(modal_dialog);
    return modal;
  }

  function create_modal_fade(options) {
    var modal_id = options.id + "_modal";
    return $("<div class='modal fade' id='" + modal_id + "'></div>");
  }

  function create_modal_dialog(options) {
    return $("<div class='modal-dialog loading-relative " + options.modal_class +"'></div>");
  }

  function create_modal_content() {
    return $("<div class='modal-content'></div>");
  }

  function create_modal_header(options) {
    var title = options.title;
    var header = $("<div class='modal-header'></div>");
    var close_button = $(
      "<button type='button' class='close' data-dismiss='modal'><span aria-hidden='true'>&times;</span><span class='sr-only'>Close</span></button>"
    );
    var title = $("<h4 class='modal-title' id='myModalLabel'>" + title +
      "</h4>");
    $(header).append(close_button);
    $(header).append(title);
    $(header).append("<br>");
    $(header).append(create_search_form(options));
    return header;
  }

  function create_modal_body(options) {
    var body = $("<div class='modal-body'></div>");
    var table = $(
      "<table class='table table-condensed table-hover table-striped table-bordered justgo-table'></table>"
    );
    var hidden_select_item_div_id = options.id + "_hidden_select_item_div";
    var hidden_select_item_div = $("<div id='" +
      hidden_select_item_div_id + "' style='display:none;'></div>");
    body.append(table);
    body.append(hidden_select_item_div);
    return body;
  }

  function create_table_head(options) {
    var tr = $("<tr></tr>");
    var can_check_all = options.check_all && !options.is_radio
    if (options.head_names) {
      for (var i = 0; i < options.head_names.length; i++) {
        if (i==0 && can_check_all) {
          tr.append("<th>" + "<input type='checkbox' name='head_check_name' id='check_all_checkbox'" +
          "style='cursor:pointer;'/>" + options.head_names[i] + "</th>");
        }else {
          tr.append("<th>" + options.head_names[i] + "</th>")
        }
      }
    }
    if (can_check_all && !bound_check_all[options.id]){
      $(document).on("click",'#check_all_checkbox', function check_all_clicked() {
        ck = document.getElementsByClassName("table_head_select")
        for (var i=0;i<ck.length;i++) {
          ck[i].trigger("click")
        }
      });
      bound_check_all[options.id] = true
    }
    return tr;
  }

  function create_tr(data, options) {
    var tr;
    if (options.type == "table") {
      tr = $("<tr></tr>");
    } else {
      var _class = "treegrid-" + data["id"];
      if (data["parent_id"]) {
        _class = _class + " treegrid-parent-" + data["parent_id"];
      }
      tr = $("<tr class='" + _class + "'></tr>");
    }

    var input_type = options.is_radio ? "radio" : "checkbox";
    for (var i = 0; i < options.fields.length; i++) {
      var td = $("<td></td>");
      var field = options.fields[i];
      var td_content;
      var data_field = data[field];
      if (options.data_formators) {
        var data_field_formator = options.data_formators[field];
        if (data_field_formator){ data_field = data_field_formator(data_field); }
      }
      if (i == 0) {
        td_content = $("<input type='" + input_type +
          "' class='table_head_select' name='head_check_name' value='" +
          data["id"] + "' node_name='" + data_field + "' id='" +
          options.id + "_" + data["id"] +
          "' style='cursor:pointer;'/><label name='" + field +
          "' style='cursor:pointer;'>" + data_field + "</label>");
        // store data on input for later use
        td_content.data('row_data', data)
      } else {
        if(data_field == null){
          data_field = '';
        }
        td_content = $("<label name='" + field +
          "' style='cursor:pointer;'>" + data_field + "</label>");
      }
      td.append(td_content);
      tr.append(td);
    }
    create_hidden_column(tr, data, options);
    tr.on("click", function(e) {
      var select_obj = $(this).find(".table_head_select")[0];

      if($(e.target).closest('.table_head_select').length > 0){
        //Chechbox or radio clicked
      } else {
        var checked = select_obj.checked;
        select_obj.checked = !checked;
      }

      var hidden_select_item_div_id = options.id +
        "_hidden_select_item_div";
      var hidden_selected_id = options.id + select_obj.value;
      var hidden_selected = $("#" + hidden_selected_id);
      if (options.is_radio) {
        $("#" + hidden_select_item_div_id).html("");
      }
      if (select_obj.checked) {
        append_selected(options, select_obj.value, $(select_obj).attr("node_name"))
        save_row_data(select_obj.value, $(select_obj).data('row_data'))
      } else {
        if (hidden_selected.length > 0) {
          hidden_selected.remove();
        }
      }
    });
    tr.css("cursor", "pointer");
    return tr;
  }

  function append_selected(options, value, node_name){
    let hidden_selected_id = options.id + value
    let hidden_selected = $("<input type='hidden' id='" + hidden_selected_id +
                            "' value='" + value + "' node_name='" + node_name + "'/>");
    $("#" + options.id + "_hidden_select_item_div").append(hidden_selected);
  }

  function save_row_data(id, data) {
    return $('#' + save_row_data_id).data(id, data)
  }

  function get_row_data(id) {
    return $('#' + save_row_data_id).data(id);
  }

  function preselect(options){
    if(!options.preselect){ return }
    let values = options.preselect[options.id.replace(/_$/, '')]
    if(!values){ return }

    let node_names = options.preselect[options.id + 'text'].split(',')

    for(let i = 0; i < node_names.length; i++){
      append_selected(options, values[i], node_names[i])
    }
    on_save(options)
  }

  function create_hidden_column(tr, data, options) {
    var hidden_fields = options.hidden_fields;
    if (hidden_fields && hidden_fields.length > 0) {
      for (var i = 0; i < hidden_fields.length; i++) {
        var td = $("<td style='display:none;'></td>");
        var field = hidden_fields[i];
        var td_content = $("<label for='" + options.id + "_" + data["id"] +
          "' class='btn btn-sm' name='" + field + "'>" + data[field] +
          "</label>");
        td.append(td_content);
        tr.append(td);
      }
    }
  }

  function clear_value(options) {
    var obj = $("#" + options.id);
    var hidden_div_id = options.id + "_hidden_div";
    var hidden_div = $("#" + hidden_div_id);
    obj.val("");
    if (hidden_div) {
      hiddens = hidden_div.find(":hidden");
      if (hiddens) {
        hidden_div.html("");
      }
    }
    if (options.on_clear) {
      options.on_clear();
    }
  }

  function create_modal_footer(options) {
    var save_button_id = options.id + "_save_select";
    var footer = $("<div class='modal-footer'></div>");

    var close_button = $(
      "<button type='button' class='btn btn-secondary' data-dismiss='modal'>关闭</button>"
    );
    var clear_button = $(
      "<button type='button' class='btn btn-danger' data-dismiss='modal'>清空</button>"
    );
    clear_button.on("click", function() {
      clear_value(options);
    });

    var save_button = $(
      "<button type='button' class='btn btn-primary' data-dismiss='modal' id='" +
      save_button_id + "'>确定</button>");

    footer.append(close_button);
    footer.append(clear_button);
    footer.append(save_button);
    return footer;
  }

  function create_search_form(options) {
    var modal_id = options.id + "_modal";
    var search_form_id = options.id + "_modal_search_form";
    var form = $("<form id='" + search_form_id +
      "' class='form-inline'></form>");
    var row = $("<div class='row' />");

    if (options.search_fields) {
      var content = $("<div class='col-md-10 row'/>");
      for (var i = 0; i < options.search_fields.length; i++) {
        var field = options.search_fields[i];
        var content_div = $("<div class='col-md-6'></div>");
        var label = $("<label class='control-label'>" + field.label +
          "：</label>");
        content_div.append(label);
        if (field.custom) {
          content_div.append(field.custom());
        } else {
          if (field.select === undefined ){
            var search_input = $("<input type='text' name='" + field.name +
            "' class='form-control input-sm'/>");
          } else {
            var search_input = $("<select name='"+ field.name +"' class='justgo-choose-"+ field.name +"' multiple='multiple' style='width:200px;'>" +
                                "</select>")
            $(function() {
              $(".justgo-choose-"+field.name+"").select2();
            });
            $.each(field.select, function(k, v){
              search_input.append("<option value='"+ k +"'>"+ v +"</options>")
            })
          }
          content_div.append(search_input);
        }
        content.append(content_div)
      }
      row.append(content);

      var search_button = $(
        "<a href='javascript:void(0);' class='btn btn-sm btn-info'>搜索</a>"
      );
      search_button.on("click", function(event) {
        reload(options);
      });
      row.append(search_button);
    }
    form.append(row);
    return form;
  }

  function create_paginate(options, page, count) {
    if (!count) count = 0;
    var max_page = parseInt(parseInt(count) / 10) + 1;
    var paginate_id = options.id + "_paginate";
    var current_page_id = options.id + "_current_page";
    var prev_page_id = options.id + "_prev_page";
    var next_page_id = options.id + "_next_page";

    var prev_button = $(
      "<a href='javascript:void(0);' class='btn btn-sm btn-info' id='" +
      prev_page_id + "'>上一页</a>");
    prev_button.on("click", function() {
      var current_page = parseInt($("#" + current_page_id).text());
      if (current_page == 1) {
        alert("已经是第一页了");
      } else {
        $("#" + current_page_id).text(current_page - 1);
        reload(options);
      }
    });

    var next_button = $(
      "<a href='javascript:void(0);' class='btn btn-sm btn-info' id='" +
      next_page_id + "'>下一页</a>");
    next_button.on("click", function() {
      var current_page = parseInt($("#" + current_page_id).text());
      if (current_page == max_page) {
        alert("已经是最后一页了");
      } else {
        $("#" + current_page_id).text(current_page + 1);
        reload(options);
      }
    })

    var paginate = $("<div id='" + paginate_id + "'></div>");
    paginate.append(prev_button);
    paginate.append($("<span id='" + current_page_id + "'>" + page +
      "</span>/<span>" + max_page + "</span>"));
    paginate.append(next_button);
    paginate.append(
      "<div style='float:right;margin-right:20px;'><span>总数&nbsp;:&nbsp;" +
      count + "&nbsp;条</span></div>");
    return paginate;
  }


  function reload(options) {
    var modal_id = options.id + "_modal";
    var search_form_id = options.id + "_modal_search_form";
    var table = $("#" + modal_id).find("table")[0];
    var hidden_div_id = options.id + "_hidden_div";
    var hidden_select_item_div_id = options.id +
      "_hidden_select_item_div";
    COM.loading_show_on_div($(".loading-relative"), "数据加载中");
    var search_data = {};
    search_data["q"] = fromToJson(search_form_id);
    if(options.only_fields){
      search_data['fields'] = options.fields
    }
    if (options.params) {
      for (field in options.params) {
        search_data[field] = options.params[field];
      }
    }
    $.ajax({
      type: options.http_verb,
      url: get_url(options),
      dataType: "json",
      data: search_data,
      success: function(response_data) {
        COM.loading_hide();
        var datas = response_data.data,
            alert_msg = response_data.alert_msg,
            modal_body = $("#"+modal_id+" .modal-body");
        $("h4", modal_body).html("");
        if(typeof(datas) == "undefined" || datas.length === 0){
          alert_msg = "<h4>"+alert_msg+"</h4>";
          modal_body.prepend(alert_msg);
          $(table).html("");
          return false;
        }
        $(table).html("");
        $(table).append(create_table_head(options));
        for (var i = 0; i < datas.length; i++) {
          data = datas[i];
          $(table).append(create_tr(data, options));
        }
        if (options.paginate && options.type != "tree") {
          var count = response_data.count,
              current_page_id = options.id + "_current_page",
              paginate_id = options.id + "_paginate",
              cuurent_page = 1;
          if ($("#" + current_page_id).length > 0) {
            cuurent_page = $("#" + current_page_id).text();
          }
          if ($("#" + paginate_id).length > 0) {
            $("#" + paginate_id).remove();
          }
          $(table).after(create_paginate(options, cuurent_page,
            count));
        }
        if (options.type == "tree") {
          $(table).treegrid();
        }
        if (options.is_radio) {
          var hidden_obj = $("#" + hidden_div_id).find(":hidden")[0];
          var hidden_select = $("#" + hidden_select_item_div_id).find(":hidden")[0];
          $(table).find(".table_head_select")
            .each(function(index, obj) {
              if ((hidden_obj && obj.value == hidden_obj.value) ||
                  (hidden_select && obj.value == hidden_select.value)) {
                obj.checked = true;
              }
            });
        } else {
          $(table).find(".table_head_select")
            .each(function(index, obj) {
              $("#" + hidden_div_id).find(":hidden").each(function(
                hidden_index, hidden_obj) {
                if (obj.value == hidden_obj.value) {
                  obj.checked = true;
                }
              });
              $("#" + hidden_select_item_div_id)
                .find(":hidden").each(function(index, hidden_obj) {
                  if (obj.value == hidden_obj.value) {
                    obj.checked = true;
                  }
                });
            });
        }
      }
    });
  }

  function get_url(options) {
    var url = options.url;
    if (options.cascade_by && options.cascade_params_key) {
      var cascade_values = new Array();
      var cascade_objs = $("[name='" + options.cascade_by + "']");
      if (cascade_objs) {
        cascade_objs.each(function(index, obj) {
          cascade_values[index] = $(obj).val();
        });
        url = url + get_url_join_str(url) + options.cascade_params_key +
          "=" + cascade_values.join(",");
      }
    }
    if (options.params) {
      for (field in options.params) {
        url = url + get_url_join_str(url) + field + "=" + options.params[
          field];
      }
    }
    if (options.paginate) {
      var current_page_id = options.id + "_current_page";
      var current_page = 1;
      if ($("#" + current_page_id).length > 0) {
        current_page = $("#" + current_page_id).text();
      }
      url = url + get_url_join_str(url) + "page=" + current_page;
    }
    return url;
  }

  function get_url_join_str(url) {
    var join_str = "?";
    if (url.indexOf("?") > 0) {
      join_str = "&";
    }
    return join_str;
  }

  JustgoChoose.get_column = function(select_obj, column_name) {
    if (select_obj && column_name) {
      // compatible for select_objs array value from on_save callback
      if (Array.isArray(select_obj)) {
        select_obj = select_obj[0]
      }
      return get_row_data($(select_obj).val())[column_name]
    }
  }

  JustgoChoose.get_columns = function(select_objs, column_name) {
    return $.map(select_objs, function (select_obj) {
      return JustgoChoose.get_column(select_obj, column_name)
    });
  }

  JustgoChoose.clear = function(id) {
    var obj = $("#" + id);
    var hidden_div_id = id + "_hidden_div";
    var hidden_div = $("#" + hidden_div_id);
    obj.val("");
    if (hidden_div) {
      hiddens = hidden_div.find(":hidden");
      if (hiddens) {
        if (hiddens.length <= 1) {
          hiddens.val("");
        } else {
          hidden_div.html("");
        }
      }
    }
  }

  global.JustgoChoose = JustgoChoose;
})(jQuery, window);

function fromToJson(form) {
  var result = {};
  var fieldArray = $('#' + form).serializeArray();
  for (var i = 0; i < fieldArray.length; i++) {
      var field = fieldArray[i];
      if (field.name in result) {
          result[field.name] += ',' + field.value;
      } else {
          result[field.name] = field.value;
      }
  }
  return result;
}
