import React from 'react';
import TagItem from "components/TagItem"
export default class TagBox extends React.Component {

  static get defaultProps() {
    return { tag_list: [], options: [], model: 'member' };
  }

  constructor(props) {
    super(props);
    this.state = { items: this.props.tag_list };
  }

  componentDidMount() {
    $('.selector').select2({ placeholder: '添加标签' });
    $('.selector').on('select2:select', (e) => {
      this.handleSelect(e);
      $('.selector').val(null).trigger('change');
    })
  }

  handleSelect = (e) => {
    let { items } = this.state;
    let select = e.target;
    if (items.indexOf(select.value) > -1) { return; }
    this.addOrRemoveTag(select.value, true);
  }

  deleteItem = (index) => {
    let items = this.state.items;
    this.addOrRemoveTag(items[index], false, index);
  }

  addOrRemoveTag = (item, addTag, index) => {
    let { model, id } = this.props;
    $.ajax({
      type: addTag ? 'POST' : 'DELETE',
      url: `/${model}s/${id}/${addTag ? 'add' : 'remove'}_tags`,
      data: { tag_list: [item] },
      success: (resp) => {
        let items = this.state.items
        if (addTag) {
          items.push(item)
        } else {
          items.splice(index, 1)
        }
        this.setState({ items: items })
        if(resp && resp.need_fresh){
          alert(resp.message);
          window.location.reload();
        }
      },
      error: (xhr) => {
        var resp = $.parseJSON(xhr.responseText)
        alert(resp.message)
      }
    });
  }

  renderItems() {
    let items = this.state.items.map((item, index) => {
      return (
        <TagItem name={item} index={index} key={index} deleteItem={this.deleteItem} />
      )
    });
    return items;
  }

  renderOptions() {
    let { options } = this.props;
    let optionDoms = [];
    options.map(function(option, index) {
      optionDoms.push(<option value={option} key={index}>{option}</option>);
    })
    return optionDoms;
  }

  render() {
    return (
      <div>
        {this.renderItems()}
        <select className='selector' style={{ minWidth: '100px' }}>
          <option key={-1}></option>
          {this.renderOptions()}
        </select>
      </div>
    )
  }
}
